import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash-es';
import { formatDate_MM_DD_YYYY, shouldHavePermission } from '../../../../../Assests/Utility';
import { AppConstant } from '../../../../../Assests/AppConstant'
import { sagaActions } from '../../../../../../sagas/sagaActions';
import WarehouseSearch from '../../../../SuperWarehouse/Components/SearchWarehouse/WarehouseSearch';
import moment from 'moment-timezone';
import "react-datepicker/dist/react-datepicker.css";
import "../../../../../Components/DatePicker/DatePicker.css"
import { CustomPaginationSS } from '../../../../Common/CustomPaginationSS/CustomPaginationSS';
import { getRemoteConfigSelector } from '../../../../../../redux/reducers/reducerSlices/RemoteConfigReducer';
import DrilldownTableSS from '../../Dashboard/DefaultDashboard/DrillDownReports/Common/DrilldownTableSS';
import { getEclipseOpsPaymentLoadselector } from '../../../../../../redux/reducers/reducerSlices/EclipseOpsPaymentLoadReducer';
import "./EclipseOpsPaymentLoad.css"
import DatePickerSS from '../../../../DatePickerSS/DatePickerSS';
import Popup from '../../../../Common/Popup/Popup';
import PDFViewer from '../../../../Common/PdfViewer/PdfViewer';
import { CustomDynamicForm } from '../../../../Common/CustomForm/CustomDynamicForm';
import view from "../../../../../Assests/Images/view.png"
import email_appts_dark from "../../../../../Assests/Images/email_appts_dark.png"
import info from "../../../../../Assests/Images/info.png"
import { getUserSelector } from '../../../../../../redux/reducers/reducerSlices/UserReducer';
import { closeUpdatesSocketConnection, initializeUpdatesSocketConnection } from '../../../../../../Utils/updateSocket';
import TinyLoader from '../../../../Common/TinyLoader/TinyLoader';
import pay from "../../../../../Assests/Images/pay.png"
import { toast } from 'react-toastify';
import { showAlert } from "../../../../../Assests/Utility";
import ModalSS from '../../../../SmartSchedule/SchedulePro/Components/Common/ModalSS/ModalSS';
import FSPayPOHistory from './FSPayPOHistory';

const EclipseOpsPaymentLoad = (props) => {
    const { themeSelected = 'light-mode' } = props;
    const dispatch = useDispatch();
    const [searchStr, setSearchStr] = useState('');
    const divRef = useRef(null);
    const remoteConfig = useSelector(getRemoteConfigSelector);
    const { allConfigration } = remoteConfig;
    const { PaginationDefaultLimit } = allConfigration
    const eclipseOpsReducer = useSelector(getEclipseOpsPaymentLoadselector)
    const { getEclipseOpsPaymentData, isLoading, initiateLoading, EclipseOpsPaymentList, LastEvaluatedKey, LastPage, eclipseServiceFee, btnLoading, manualPaymentMethodsList } = eclipseOpsReducer;
    const user = useSelector(getUserSelector);
    const { userType, userAccountDetails, permissions } = user
    const [eclipseOpsPayLoadArr, setEclipseOpsPayLoadArr] = useState([]);
    const [isError, setIsError] = useState(false)
    const first = useRef(true);
    const firstPageLoad = useRef(true);
    const [showStartDate, setShowStartDate] = useState(false)
    const [showEndDate, setShowEndDate] = useState(false)
    const [webPayPermission, setwebPayPermission] = useState(false)
    const [manualPaymentPermission, setmanualPaymentPermission] = useState(false)
    const [receiptPermission, setreceiptPermission] = useState(false)
    const [emailPermission, setemailPermission] = useState(false)
    const [selectedStartDate, setSelectedStartDate] = useState(() => {
        const dStart = new Date()
        dStart.setHours(0, 0, 0, 0);
        return dStart
    })
    const [selectedEndDate, setSelectedEndDate] = useState(() => {
        const dEnd = new Date()
        dEnd.setHours(23, 59, 59, 0);
        return dEnd
    })
    const [showModal, setShowModal] = useState(false);
    const [selectedObj, setSelectedObj] = useState(null);
    const [editData, setEditData] = useState({});
    const [editPaymentData, setEditPaymentData] = useState({});
    const [resetFormData, setResetFormData] = useState(0);
    const [particularFieldValue, setParticularFieldValue] = useState("");
    const [showShareReceiptModal, setShowShareReceiptModal] = useState(false)
    const [loading, setLoading] = useState(false)
    const [maxEndDate, setMaxEndDate] = useState(new Date())
    const userPermission = useSelector(getUserSelector);
    const { selectedWarehouse } = userPermission;
    const [socketRef, setSocketRef] = useState(null)
    const [page, setPage] = useState(1);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [currentIndex, SetCurrentIndex] = useState("NA")
    const [paymentMethod, setPaymentMethod] = useState(null)
    const [fSPayPOHistoryModal, setFSPayPOHistoryModal] = useState(null)
    const [poData, setPoData] = useState(null)


    const paymentOptions = [
        {
            id: "1",
            name: "INSTA MONEY",
            value: "INSTA-MONEY-MANUAL"
        },
        {
            id: "2",
            name: "US BANK",
            value: "US-BANK-MANUAL"
        },
        {
            id: "3",
            name: "Relay",
            value: "RELAY_MANUAL"
        },
        {
            id: "4",
            name: "EFS",
            value: "EFS_MANUAL"
        },
        {
            id: "5",
            name: "T-Chek",
            value: "T_CHEK_MANUAL"
        },
        {
            id: "6",
            name: "Credit Card",
            value: "CREDIT_CARD_MANUAL"
        },
        {
            id: "7",
            name: "Comdata",
            value: "COMDATA_MANUAL"
        },
    ]


    /**
        * Used to perform neccesary action on load
        */
    useEffect(() => {
        dispatch({ type: sagaActions.INITIATE_FSPAY_LOADING, payload: false })
        dispatch({ type: sagaActions.CLEAR_ECLIPSE_OPS_PAYMENT_LIST })
        dispatch({ type: sagaActions.CLEAR_ECLIPSE_SERVICE_FEE })
        let tout = setTimeout(() => {
            dispatch({ type: sagaActions.GET_MANUAL_PAYMENT_METHODS_LIST })
        }, 0)
        return () => {
            dispatch({ type: sagaActions.CLEAR_ECLIPSE_OPS_PAYMENT_LIST })
            clearTimeout(tout)
        }
    }, [])


    useEffect(() => {
        if (permissions) {
            setwebPayPermission(shouldHavePermission(permissions, "975"))
            setreceiptPermission(shouldHavePermission(permissions, "976"))
            setemailPermission(shouldHavePermission(permissions, "977"))
            setmanualPaymentPermission(shouldHavePermission(permissions, "978"))

        }
    }, [permissions])

    /**
 * This function used to fetch eclipse ops details by connecting with API
 */
    const fetchData = (pageNo = 1, limit = PaginationDefaultLimit) => {
        if ((!LastPage || pageNo === 1) && !isLoading) {
            setTimeout(() => {

                // const eDate = moment(selectedEndDate).hours(23).minutes(59).seconds(59)
                selectedEndDate.setHours(23, 59, 59, 0)
                const params = {
                    "startDate": moment(selectedStartDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                    "endDate": moment(selectedEndDate).format(AppConstant.commonStrings.YYYY_MM_DD_T_HH_mm_ss),
                    "currentPage": pageNo,
                    "itemsPerPage": limit,
                    "searchParam": searchStr,
                    "filterParam": selectedWarehouse?.warehouseName,
                    "LastEvaluatedKey": pageNo === 1 ? null : LastEvaluatedKey
                }

                dispatch({ type: sagaActions.GET_ECLIPSE_OPS_PAYMENT_LOAD_DETAILS, payload: params });
            }, 0)
        }

    }
    useEffect(() => {
        if (firstPageLoad.current) {
            firstPageLoad.current = false
            return
        }
        let tout;
        tout = setTimeout(() => {
            if (page) {
                fetchData(page)
            }
        }, 1000)

        return () => clearTimeout(tout)
    }, [page])

    const handelInfiniteScroll = (e) => {
        try {
            if (e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight && !isLoading) {
                setLoading(true);
                setPage((prev) => prev + 1);
            }
        } catch (error) {
        }
    };


    /**
     * Tabular array of headers to display
     */
    const titleDD = [
        { id: 'LocID', name: 'Site' },
        { id: 'poNumber', name: 'PO' },
        { id: 'Carrier', name: 'Carrier' },
        // { id: 'driverName', name: 'Driver Name' },
        { id: 'driverNumber', name: 'Driver No.' },
        { id: 'Bill Amount', name: '$Bill' },
        { id: 'Addl. Charges', name: '$Addl' },
        { id: 'amount', name: '$Fee' },
        { id: 'amount', name: '$Invoice' },
        { id: 'amount', name: '$Paid' },
        { id: 'paymentStatus', name: 'Status' },
        { id: 'authNumber', name: 'Auth No.' },
        { id: 'transactionType', name: 'Mode' },
        { id: 'transactionStatus', name: 'Comments' },
        { id: 'CreatedOn', name: 'Created' },
        { id: 'UpdatedOn', name: 'Updated' },
        { id: 'action', name: 'Action' },
    ]

    useEffect(() => {
        if (!initiateLoading) {
            closeManualPayment()
        }
    }, [initiateLoading])

    // useEffect(()=>{
    //     if (!btnLoading && currentIndex!=='NA') {
    //         SetCurrentIndex("NA")
    //     }
    // },[btnLoading])

    useEffect(() => {
        if (selectedObj && selectedObj.LocID && paymentMethod) {
            getEclipseServiceFee()
        }
    }, [paymentMethod])

    useEffect(() => {
        if (eclipseServiceFee && selectedObj) {
            // manualPaymentHandler(selectedObj)
            setParticularFieldValue({ ...particularFieldValue, amount: `$${getManualAmount(selectedObj, true, eclipseServiceFee)}`, serviceFee: `$${calculateServiceFee(selectedObj, eclipseServiceFee)}`, billAmount: `$${parseFloat(getTheNumberWithoutSpaces(selectedObj.CCCollect)).toFixed(2)}` })
        }
    }, [eclipseServiceFee])

    useEffect(() => {
        if (loading && !initiateLoading) {
            setLoading(initiateLoading)
            setShowShareReceiptModal(false)
        }
    }, [initiateLoading])


    /**
     * checking the eclipse ops list 
     */
    useEffect(() => {
        if (EclipseOpsPaymentList) {
            setEclipseOpsPayLoadArr(EclipseOpsPaymentList)
        } else {
            setEclipseOpsPayLoadArr([])
        }

    }, [EclipseOpsPaymentList])


    /**
     * validates and gets the details
     */
    useEffect(() => {
        if (!isError && selectedStartDate && selectedEndDate) {
            setTimeout(() => {
                setPage(1)
                fetchData()
            }, [0])
        }
    }, [selectedStartDate, selectedEndDate, isError])


    /**
    * used to filter and set the data to list if the search string is greater than 3 characters
    */
    useEffect(() => {
        if (first.current) {
            first.current = false;
            return;
        }
        if (searchStr.length > 3) {
            setPage(1)
            fetchData();
        } else if (searchStr === '') {
            setPage(1)
            fetchData();
        }
    }, [searchStr])


    useEffect(() => {
        validateURLandFetchPayments()
        return () => {
            if (socketRef) {
                const socketId = selectedWarehouse?.warehouseName || "FSADMIN"
                closeUpdatesSocketConnection(socketRef, socketId)
            }
        }
    }, [])

    /**
      * Method is used to handle truck update and fetch latest truck data
      */
    const onUpdateSocketReceived = (message, date) => {
        let parsedMessage = JSON.parse(message)
        if (typeof parsedMessage === 'string') {
            parsedMessage = JSON.parse(parsedMessage)
        }

        if (!_.isEmpty(parsedMessage)) {
            const { type } = parsedMessage;
            switch (type) {
                case 'FS_PAYMENT':
                    if (selectedStartDate && selectedEndDate) {
                        let isAlreadyExist = _.some(EclipseOpsPaymentList, item =>
                            item.RecID === parsedMessage.RecID && item.RelayStatus === parsedMessage.RelayStatus && item.LocID === parsedMessage.LocID)
                        if (!selectedWarehouse?.warehouseName || selectedWarehouse?.warehouseName === parsedMessage.LocID) {
                            let startDate = moment(selectedStartDate)
                            let endDate = moment(selectedEndDate)
                            const isInRange = moment(parsedMessage.CreatedOn).isBetween(startDate, endDate, undefined, "[]")
                            if (!isAlreadyExist && isInRange) {
                                dispatch({ type: sagaActions.UPDATE_REAL_TIME_FS_PAYMENT_LIST, payload: parsedMessage })
                            }
                        }

                    }
                    break;
                default:
                    break;
            }
        }


    }


    /**
     * Method is used to captcure socket error and re initate the connection 
     * @param {*} error 
     */
    const onUpdateSocketError = (error) => {
        validateURLandFetchPayments()
    }

    /**
     * Method is used to capture socket close
     * @param {*} data 
     */
    const onUpdateSocketClose = (data) => {

        //check if user is on same page and if it NORMAL closure or not or IDLE session closure
        //initiatesocket connection
        // refetch appointment data
        if (data.code !== 1000) {
            validateURLandFetchPayments()
        }
    }

    const validateURLandFetchPayments = () => {

        if (window.location.pathname === '/fspaypayment') {
            const socketId = selectedWarehouse?.warehouseName || "FSADMIN"
            let socketRefTemp = initializeUpdatesSocketConnection(socketId, AppConstant.commonStrings.truckUpdateSocketUrl, onUpdateSocketReceived, onUpdateSocketError, onUpdateSocketClose, null)
            setSocketRef(socketRefTemp)
        }
    }

    /**
     * method is used to check is paid
     * @param {*} status 
     */
    const isPaid = (status) => {
        if (status && status.toUpperCase() === "SUCCESS") {
            return true
        }
        return false
    }


    /**
     * Function to render status
     * @param {*} status 
     * @returns JSX
     */
    const getPaymentStatus = (status) => {
        if (status?.toUpperCase() === "CREATED" || !status) {
            return <span className='color-black'>{"Pending"}</span>
        } else if (status.toUpperCase() === "SUCCESS") {
            return <span className='textGreen'>{status}</span>
        } else {
            return <span className='textRed'>{status}</span>
        }
    }

    /**
     * Function to render Receipt popup
     * @returns 
     */
    const showReceiptPopup = () => {
        const popupObj = {
            id: 'showReceiptPopup1',
            modalLabel: 'showReceiptPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'xl',
            title: "Receipt",
            bodyText: viewReceipt(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeCurrentpopup} themeSelected={themeSelected} showModal={showModal} />
    }

    /**
     * Function to close receipt popup
     */
    const closeCurrentpopup = () => {
        setShowModal(false)
    }

    /**
     * Function to render receipt
     * @returns 
     */
    const viewReceipt = () => {
        return (
            <>
                {selectedObj && selectedObj.receiptUrl ? <PDFViewer pdfUrl={selectedObj.receiptUrl} width={"100%"} height={"500px"} /> : <span className='d-flex justify-content-center align-items-center f-18 fw-bold' style={{ height: "500px", width: "100%" }}>No Receipt Found!</span>}
            </>
        )
    }

    const formFieldList = [
        {
            name: "email",
            type: "email",
            size: "col-sm-12",
            position: 1,
            title: AppConstant.login.email,
            formValidation: {
                required: true,
                validateEmail: true,
            },
        }]

    /**
 * Function to render share Receipt popup
 * @returns 
 */
    const showReceiptSharePopup = () => {
        const popupObj = {
            id: 'showReceiptSharePopup1',
            modalLabel: 'showReceiptSharePopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Share Receipt",
            bodyText: shareReceipt(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.superUserAdmin.warehouseAdmin.createWarehouseForm.saveButtonTitle
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={closeShareReceiptpopup} themeSelected={themeSelected} showModal={showShareReceiptModal} />
    }

    /**
     * Function to close share receipt popup
     */
    const closeShareReceiptpopup = () => {
        setShowShareReceiptModal(false)
    }

    const cancelbtndata = {
        show: true,
        text: AppConstant.comments.resetbutton,
    };

    const submitbtnData = {
        show: true,
        text: AppConstant.commonStrings.share,
    };


    const cancelbtndataPayment = {
        show: false,
        text: AppConstant.comments.cancelCommentButtonTitle,
    };

    const submitbtnDataPayment = {
        show: true,
        text: AppConstant.eclipseOpsPaymentLoad.paymentComplete
    };


    const getEclipseServiceFee = () => {
        const getMode = (type) => {
            if (type === "RELAY" || type === "COMDATA" || type === "EFS" || type === "TCHEK" || type === "CHECK") {
                return "CHECK"
            } else if (type === "CREDITCARD") {
                return "CCARD"
            }

        }
        const initiateGetServiceFee = (type) => {
            const body = {
                "locId": selectedObj.LocID,
                "feeType": getMode(type)
            }
            dispatch({ type: sagaActions.GET_ECLIPSE_SERVICE_FEE, payload: body })
        }
        switch (paymentMethod) {
            case "RELAY_MANUAL":
                return initiateGetServiceFee("RELAY")
            case "COMDATA_MANUAL":
                return initiateGetServiceFee("COMDATA")
            case "EFS_MANUAL":
                return initiateGetServiceFee("EFS")
            case "T_CHEK_MANUAL":
                return initiateGetServiceFee("TCHEK")
            case "CREDIT_CARD_MANUAL":
                return initiateGetServiceFee("CREDITCARD")
            case "INSTA-MONEY-MANUAL":
                return initiateGetServiceFee("CHECK")
            case "US-BANK-MANUAL":
                return initiateGetServiceFee("CHECK")
        }

    }


    const getPaymentOptions = () => {

        return (
            manualPaymentMethodsList &&
            manualPaymentMethodsList?.map((data, index) => {
                return (
                    <option key={data.ID} value={data.ID}>
                        {data.PaymentType}
                    </option>
                );
            })
        );
    }

    const formFieldListPayment = [
        {
            name: "po",
            type: "text",
            size: "col-sm-12",
            position: 1,
            title: "PO",
            formValidation: {
                required: true,
            },
            isEditDisable: true,
        },
        {
            name: "paymentType",
            type: "select",
            size: "col-sm-12",
            position: 1,
            title: "Payment Type",
            formValidation: {
                required: true,
            },
            getSelectData: getPaymentOptions,
        },
        {
            name: "billAmount",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: "Bill Amount",
            formValidation: {
                required: true,
            },
            hideElement: {
                isHidden: paymentMethod && eclipseServiceFee ? true : false
            },
            isEditDisable: true,
            loading: btnLoading
        },
        {
            name: "serviceFee",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: "Service Fee",
            formValidation: {
                required: true,
            },
            hideElement: {
                isHidden: paymentMethod && eclipseServiceFee ? true : false
            },
            isEditDisable: true,
            loading: false
        },
        {
            name: "amount",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: "Total Amount",
            formValidation: {
                required: true,
            },
            hideElement: {
                isHidden: paymentMethod && eclipseServiceFee ? true : false
            },
            isEditDisable: true,
            loading: false
        },
        {
            name: "check",
            type: "text",
            size: "col-sm-12",
            position: 2,
            title: "Check/Auth No.",
            formValidation: {
                required: true,
            },
        }]

    const formatFSPhoneNumber = (phoneNumber) => `+1${phoneNumber.split("-").join("")}`

    const getSummitedFormDataPayment = (data) => {
        const transactionData = {
            recID: selectedObj.RecID,
            transactionType: data.paymentType,
            paymentCode: data.check,
            driverLastName: userAccountDetails?.LastName,
            driverFirstName: userAccountDetails?.FirstName,
            carrierName: "",
            fullAmount: getManualAmount(selectedObj, true, eclipseServiceFee),
            driverPhoneNumber: formatFSPhoneNumber(selectedObj?.Field36),
            user_number: formatFSPhoneNumber(selectedObj?.Field36),
            transactionDateTime: moment(),
            timeZone: "UTC+6",
            unitNum: null,
            tripNum: null,
            relaySiteId: selectedObj.LocID,
            tabletype: "eclipseOps",
            serviceFee: calculateServiceFee(selectedObj, eclipseServiceFee),
            paymentType: "Full Payment",
        }
        dispatch({ type: sagaActions.FS_PAY_MANUAL_PAYMENT, payload: transactionData })
    }

    const getFormFieldDataPayment = (data, name) => {
        if (name === 'paymentType') {
            setPaymentMethod(data)
        }
    };

    const ShowPaymentOptions = () => {
        return (
            <>
                <CustomDynamicForm
                    formFieldList={formFieldListPayment}
                    sort={true}
                    cancelBtn={null}
                    submitbtn={submitbtnDataPayment}
                    themeSelected={themeSelected}
                    getFormFieldData={getFormFieldDataPayment}
                    getSummitedFormData={getSummitedFormDataPayment}
                    editFormData={editPaymentData}
                    loading={initiateLoading}
                    resetFormData={resetFormData}
                    setParticularFieldValue={particularFieldValue}
                    customDisableSave={btnLoading}
                />
            </>
        )
    }

    const closeManualPayment = () => {
        setShowPaymentModal(false);
        setSelectedObj(null)
        setPaymentMethod(null)
        // setEditPaymentData({})
        // setParticularFieldValue({})
        dispatch({ type: sagaActions.CLEAR_ECLIPSE_SERVICE_FEE })
    }

    const showPaymentPopup = () => {
        const popupObj = {
            id: 'showPaymentPopup1',
            modalLabel: 'showPaymentPopup1',
            showClose: true,
            showfooter: false,
            showHeader: true,
            sendDriverNotification: false,
            modalSize: 'md',
            title: "Payment",
            bodyText: ShowPaymentOptions(),
            subText: "",
            keyboard: false,
            backdrop: "static",
            footerBtn: {
                btn1: {
                    show: true,
                    text: AppConstant.comments.cancelCommentButtonTitle
                },
                btn2: {
                    show: true,
                    text: AppConstant.eclipseOpsPaymentLoad.paymentComplete
                }
            },
        }

        return <Popup {...popupObj} popupBtnHandler={(text) => () => ''} closepopup={() => { closeManualPayment() }} themeSelected={themeSelected} showModal={showPaymentModal} />
    }

    const getSummitedFormData = (data) => {
        setLoading(true)
        let apiParams = { ...data }
        apiParams.id = selectedObj.RecID
        dispatch({ type: sagaActions.SHARE_RECEIPT_FOR_ECLIPSE_OPS_PAYMENT_LOAD, payload: apiParams })
    };

    /**
     * Function to render share receipt form
     * @returns 
     */
    const shareReceipt = () => {
        return (
            <CustomDynamicForm
                formFieldList={formFieldList}
                sort={true}
                cancelBtn={cancelbtndata}
                submitbtn={submitbtnData}
                themeSelected={themeSelected}
                getFormFieldData={() => { }}
                getSummitedFormData={getSummitedFormData}
                editFormData={editData}
                loading={loading}
                resetFormData={resetFormData}
                setParticularFieldValue={particularFieldValue}
            />
        );
    }

    const getMode = (data) => {
        if (data?.isManualTransaction) {
            return `${data?.transactionType}${data?.driverFirstName && "/" + data?.driverFirstName}`
        }
        return data?.transactionType
    }
    const mapTransactionStatus = (data) => {
        const { transactionStatus } = data
        console.log("🚀 ~ mapTransactionStatus ~ data:", data)
        const phoneNumberUpdate = `Old Number: ${data.oldPhoneNumber}`
        switch (transactionStatus) {
            case 'PO_DELETED':
                return (
                    <span id='required'>PO Deleted</span>
                )

            case 'CCCOLLECT_UPDATED':
                return 'Amount Updated'
            case 'RESTACK_UPDATED':
                return 'Amount Updated'
            case 'PONUMBER_UPDATED':
                return 'PO Number Updated'
            case 'PHONE_NUMBER_UPDATED':
                return (
                    <span title={phoneNumberUpdate} id='required'>Phone Number Updated</span>
                )
            case 'PAYMENT_METHOD_UPDATED':
                return (
                    <span id='required'>Payment Method Updated to {data.Field31}</span>
                )

            default:
                return '-'
        }
    }

    const fetchFSPayPOHistoryDetails = (data) => {

        setPoData(data)
        setFSPayPOHistoryModal(true)
    }

    /**
     * This function format the data
     * @param {*} data 
     * @returns 
     */
    const formatListDataDrillDown = (data) => {

        return data.map((el, index) => {
            return {
                0: el.LocID,
                1: <span style={{ textDecoration: 'underline', color: 'blue' }} onClick={() => fetchFSPayPOHistoryDetails(el)}>{el.PONumber}</span>,
                2: el.Carrier,
                // 3: el.Field35!='NA' && el.Field35? el.Field35 : '-',
                3: formatPhoneNumber(el.Field36) || '-',
                4: `$${parseFloat(getTheNumberWithoutSpaces(el?.CCCollect)).toFixed(2)}` || '$0',
                5: `$${parseFloat(getTheNumberWithoutSpaces(el?.ReStack)).toFixed(2)}` || '$0',
                6: `$${el.serviceFee ? parseFloat(el.serviceFee).toFixed(2) : 0}`,
                7: getAmount(el) || '-',
                8: getPaidAmount(el) || '-',
                9: getPaymentStatus(el.RelayStatus),
                10: el.RelayCheckoutNumber || '-',
                11: getMode(el) || "-",
                12: mapTransactionStatus(el),
                13: `${moment(el.CreatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.CreatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || AppConstant.commonStrings.hypen,
                14: `${moment(el.UpdatedOn).format(AppConstant.commonStrings.MM_DD_YYYY_dateFormat)}\n${moment(el.UpdatedOn).format(AppConstant.commonStrings.HH_mm_dateFormat)}` || AppConstant.commonStrings.hypen,
                15: actionsButton(el, index)
            }
        })
    }

    /**
     * Method is used to handle pay click
     */
    const onPayClick = (el) => {
        if (isPaid(el?.RelayStatus)) {
            showAlert(toast.TYPE.ERROR, "Payment already made.");
        } else {
            window.open(process.env.REACT_APP_FSPAYMENTURL, '_blank', 'noopener,noreferrer')

        }
    }

    const manualPaymentHandler = (data) => {
        if (isPaid(data?.RelayStatus)) {
            showAlert(toast.TYPE.ERROR, "Payment already made.");
            return
        }
        setShowPaymentModal(true)
        setEditPaymentData({ ...editPaymentData, po: data.PONumber, amount: `$${getManualAmount(data, false, null)}`, serviceFee: `$${calculateServiceFee(data, eclipseServiceFee)}`, billAmount: `$${parseFloat(getTheNumberWithoutSpaces(data.CCCollect)).toFixed(2)}` })
        setParticularFieldValue({ ...particularFieldValue, po: data.PONumber, amount: `$${getManualAmount(data, false, null)}`, serviceFee: `$${calculateServiceFee(data, eclipseServiceFee)}`, billAmount: `$${parseFloat(getTheNumberWithoutSpaces(data.CCCollect)).toFixed(2)}` })
        setSelectedObj(data)
    }

    /**
     * Function to render action buttons
     * @param {*} data 
     * @returns 
     */
    const actionsButton = (data, index) => {
        let disabled = false;
        if ((data.transactionStatus === 'PAYMENT_METHOD_UPDATED' && _.upperCase(data.Field31) !== 'FSPAY') || data.transactionStatus === 'PO_DELETED') {
            disabled = true;
        }
        return (
            <div className='d-flex gap-2 justify-content-center'>

                {
                    webPayPermission && <span className={disabled ? 'disabled' : 'pointer-cursor'} onClick={() => {
                        if (!disabled) {
                            onPayClick(data)
                        }
                    }}>
                        {
                            <img src={pay} alt='pay' className='actionImg' />
                        }
                    </span>
                }


                {receiptPermission &&
                    <span className={disabled ? 'disabled' : 'pointer-cursor'} onClick={() => {
                        if (!disabled) {
                            viewAction(data)
                        }
                    }
                    }>
                        {
                            <img src={view} alt='view' className='actionImg' />
                        }
                    </span>
                }
                {
                    emailPermission &&
                    <span className={disabled ? 'disabled' : 'pointer-cursor'}
                        onClick={() => {
                            if (!disabled) {
                                setShowShareReceiptModal(true);
                                setSelectedObj(data)
                            }
                        }}>
                        {
                            <img src={email_appts_dark} alt='view' className='actionImg' />
                        }
                    </span>
                }
                {
                    manualPaymentPermission &&
                    <span className={disabled ? 'disabled' : 'pointer-cursor'}
                        onClick={() => {
                            if (!disabled) {

                                manualPaymentHandler(data)
                            }
                        }}
                        title='Manual Payment'
                    >
                        {
                            <img src={info} alt='info' className='actionImg' />
                        }
                    </span>
                }
            </div>
        )
    }

    /**
     * Function to handle view button
     */
    const viewAction = (data) => {
        setSelectedObj(data)
        setShowModal(true)
    }

    /**
     * Format the amount and return the amount
     * @param {*} data 
     * @returns 
     */
    const getAmount = (data) => {
        const ccCollect = getTheNumberWithoutSpaces(data.CCCollect);
        const reStack = getTheNumberWithoutSpaces(data.ReStack);
        const sumAnount = parseFloat(ccCollect) + parseFloat(reStack) + parseFloat(data.serviceFee || 0);
        return (
            `$${sumAnount.toFixed(2)}`
        );
    };

    const calculateServiceFee = (data, eclipseServiceFeeData) => {
        const ccCollect = getTheNumberWithoutSpaces(data.CCCollect);
        const reStack = getTheNumberWithoutSpaces(data.ReStack);
        const sumAnount = parseFloat(ccCollect) + parseFloat(reStack);
        const eclipseFee = parseFloat(eclipseServiceFeeData?.Fee)
        if (eclipseServiceFeeData && eclipseServiceFeeData.FeeType === "CCARD") {
            return (sumAnount * eclipseFee).toFixed(2)
        }
        return eclipseFee.toFixed(2)
    }

    const getManualAmount = (data, serviceFee, eclipseServiceFeeData) => {
        const ccCollect = getTheNumberWithoutSpaces(data.CCCollect);
        const reStack = getTheNumberWithoutSpaces(data.ReStack);
        const sumAnount = parseFloat(ccCollect) + parseFloat(reStack);
        const eclipseFee = parseFloat(eclipseServiceFeeData?.Fee)
        if (!serviceFee) {
            return sumAnount.toFixed(2)
        }
        if (eclipseServiceFeeData && eclipseServiceFeeData.FeeType === "CCARD") {
            return (sumAnount + (sumAnount * eclipseFee)).toFixed(2)
        }
        return (sumAnount + eclipseFee).toFixed(2)
    }

    /**
    * Format the amount and return the paid amount
    * @param {*} data 
    * @returns 
    */
    const getPaidAmount = (data) => {
        if (data.RelayStatus === 'Success') {
            const ccCollect = getTheNumberWithoutSpaces(data.CCCollect);
            const reStack = getTheNumberWithoutSpaces(data.ReStack);
            const sumAnount = parseFloat(ccCollect) + parseFloat(reStack) + parseFloat(data.serviceFee || 0)
            return (
                `$${sumAnount.toFixed(2)}`
            );
        } else if (data.RelayStatus === 'Partial Payment') {
            const ccCollect = data?.totalPaid;
            const ccCollectFin = parseFloat(ccCollect);
            return (
                `$${ccCollectFin.toFixed(2)}`
            );
        }
        return '$0'
    };

    /**
     * function used to remove spaces in the number
     * @param {*} number 
     * @returns 
     */
    const getTheNumberWithoutSpaces = (number) => {
        return number.replace(/\s/g, "");
    };

    /**
     * Helper to format the mobile number
     * @param {*} number 
     * @returns 
     */
    const formatPhoneNumber = (number) => {
        // if (number.length === 10) {
        //     return `(${number.slice(0, 3)}) ${number.slice(3, 6)}-${number.slice(6)}`;
        // }
        // return ""

        // Use a regular expression to match the parts of the phone number
        let match = number.match(/(\d{3})-(\d{3})-(\d{4})/);

        // If the input matches the expected format
        if (match) {
            // Return the formatted phone number
            return `(${match[1]}) ${match[2]}-${match[3]}`;
        } else {
            return number
        }
    }

    /**
     * This function is used to get the data and validate
     * @returns 
     */
    const getBodyData = () => {
        if (eclipseOpsPayLoadArr && eclipseOpsPayLoadArr.length) {
            return formatListDataDrillDown(eclipseOpsPayLoadArr)
        }
        else {
            return []
        }
    }


    /**
     * Function to return max end date
     */
    const getMaxEndDate = (startDate) => {
        let currentDate = new Date();
        let threeMonthslater = new Date(startDate);
        threeMonthslater.setMonth(startDate.getMonth() + 3);
        threeMonthslater.setDate(threeMonthslater.getDate() - 1)
        if (currentDate <= threeMonthslater) {
            setMaxEndDate(currentDate)
        } else {
            setMaxEndDate(threeMonthslater)
        }
    }

    /**
     * Function to handle startdate change
     * @param {*} date 
     */
    const handleStartDate = (date) => {
        let threeMonthslater = new Date(date);
        threeMonthslater.setMonth(date.getMonth() + 3);
        if (threeMonthslater < selectedEndDate) {
            setSelectedEndDate(null)
        }
        setSelectedStartDate(date)
        getMaxEndDate(date)
    }

    const toggleModal = () => {
        setFSPayPOHistoryModal(!fSPayPOHistoryModal);
    }
    return (
        <div className=''>
            <div className='d-flex justify-content-between my-2 mx-3'>
                <div className='fw-bold'>{AppConstant.fsWarehouse.eclipseOpsHeader}</div>
                <div className='d-flex justify-content-end align-items-end'>
                    <div className='d-flex appt-report-header gap-3 align-items-center'>
                        <div className="pos-rel">
                            <div className='d-flex flex-column align-items-start'>
                                <label className='boldText m-0 f-14'><i className="fa-regular fa-calendar"></i> Start Date </label>
                                <button
                                    className={'datePickerBtn h-100 w-100'}
                                    onClick={() => setShowStartDate(!showStartDate)}
                                    disabled={isLoading}
                                >
                                    <span className="btn-title">{selectedStartDate ?
                                        formatDate_MM_DD_YYYY(selectedStartDate) : 'Start Date'}</span>
                                    <i
                                        className="fa-regular fa-calendar"
                                        style={{ marginInline: "9px" }}
                                    ></i>

                                </button>
                                {
                                    showStartDate && <div className="appt-summary-ss-datePicker" >
                                        <DatePickerSS maxDate={new Date()} setSelectedDate={handleStartDate} selectedDate={selectedStartDate} closeCalendar={() => { setShowStartDate(!showStartDate) }} />
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="pos-rel">
                            <div className='d-flex flex-column align-items-start'>
                                <label className='boldText m-0 f-14'><i className="fa-regular fa-calendar"></i> End Date </label>

                                <button
                                    className={'datePickerBtn h-100 w-100'}
                                    onClick={() => setShowEndDate(!showEndDate)}
                                    disabled={isLoading}
                                >
                                    <span className="btn-title">{selectedEndDate ?
                                        formatDate_MM_DD_YYYY(selectedEndDate) : 'End Date'}</span>
                                    <i
                                        className="fa-regular fa-calendar"
                                        style={{ marginInline: "9px" }}
                                    ></i>

                                </button>
                                {
                                    showEndDate && <div className="appt-summary-ss-datePicker">
                                        <DatePickerSS setSelectedDate={setSelectedEndDate} minDate={new Date(selectedStartDate)} maxDate={maxEndDate} selectedDate={selectedEndDate} closeCalendar={() => { setShowEndDate(!showEndDate) }} />
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className='ms-2 w-50'>
                        <WarehouseSearch
                            placeholder={AppConstant.poManagement.searchPOSection.searchTitleStrPo}
                            inputValue={searchStr}
                            cancelSearch={() => setSearchStr('')}
                            onChangeHandler={(text) => setSearchStr(text)} />
                    </div>

                </div>
            </div>
            <div className='eclipseOpsListContainer' style={{ maxHeight: userType === "SUPERADMIN" ? "55vh" : "65vh" }} ref={divRef} onScroll={(e) => handelInfiniteScroll(e)}>
                <DrilldownTableSS
                    key={Math.random()}
                    themeSelected={themeSelected}
                    bodyData={getBodyData()}
                    titleData={titleDD}
                    showCollapse={false}
                    showInput={false}
                    showSort={true}
                    hasCollapsableContent={false}
                    collapsableData={[]}
                    accordionPrimaryIndex={1}
                    drillDownReport={eclipseOpsPayLoadArr?.data?.Items}
                    initiateDrillDownReport={page === 1 && isLoading ? true : false}
                    loaderArray={16}
                    sortingAction={sagaActions.SORT_SS_USER_TABLE}
                    subRoot={{ pathName: 'eclipseOpsPaymentLoadObj' }}
                    showUTCTime={true}
                />
            </div>
            {isLoading ? <div className='d-flex justify-content-center w-100'>
                <TinyLoader />
            </div> : ""
            }
            {showReceiptPopup()}
            {showReceiptSharePopup()}
            {showPaymentPopup()}
            {fSPayPOHistoryModal &&
                <ModalSS toggleModal={toggleModal}>
                    <FSPayPOHistory
                        themeSelected={themeSelected}
                        data={poData}
                    />
                </ModalSS>

            }
        </div>
    )
}

export default EclipseOpsPaymentLoad