// ALBERTSONS TOLLESON - 'C64D147F-D6F0-474B-88D4-054D207B3CD5'   

import { isDevEnv } from "./Utility";

// ALBERTSONS IRVINE - '23B8A3DE-1DC6-43E1-943E-51F6B7E6147C' 
export const AppConstant = {
    commonStrings: {
        truckUpdateSocketUrl: isDevEnv && isDevEnv() ? "wss://8s1eoe4zpj.execute-api.us-west-2.amazonaws.com/dev/" :"wss://a59okwxv5j.execute-api.us-east-2.amazonaws.com/production/",
        cscReferenceImageLink: "https://freightsmit-fileupload-bucket-dkg-prod.s3.us-east-2.amazonaws.com/sampleCVV.png",
        bkgImgLink: 'https://freightsmit-fileupload-bucket-dkg-prod.s3.us-east-2.amazonaws.com/defaultLandingBg.jpg',
        wordMark: "https://freightsmith-ims-logo.s3.us-west-2.amazonaws.com/FreightSmith_WORDMARK.png",
        logo_wordMark: "https://freightsmith-ims-logo.s3.us-west-2.amazonaws.com/logo_wordmark.png",
        logo_small_wordMark: "https://freightsmith-ims-logo.s3.us-west-2.amazonaws.com/IMS_LOGO_SMALL.png",
        schedulerProLogo: 'https://freightsmith-ims-logo.s3.us-west-2.amazonaws.com/SchedulerPro.png',
        findPoPdfHelperFile: 'https://freightsmit-fileupload-bucket-dkg-prod.s3.us-east-2.amazonaws.com/Sample+BOL+for+SchedulePro.pdf',
        scheduleProBG: 'https://freightsmit-fileupload-bucket-dkg-prod.s3.us-east-2.amazonaws.com/ScheduleProBG.png',
        scheduleProTutorial: `${process.env.REACT_APP_SS_TUTORIAL}?autoplay=1`,
        scheduleProCarrierTutorial: `${process.env.REACT_APP_SPRO_CARRIER_TUTORIAL}?autoplay=1`,
        scheduleProWarehouseTutorial: `${process.env.REACT_APP_SPRO_WAREHOUSE_TUTORIAL}?autoplay=1`,
        true: 'true',
        false: 'false',
        center: 'center',
        addMore: 'Add More',
        warehouse: 'Warehouse',
        location: 'Location',
        info: 'info',
        commingSoon: 'Coming Soon...',
        noDataFound: 'No Data Found !',
        darkModeFilterString: 'dark-mode',
        lightModeFilterString: 'light-mode',
        loading: "Loading",
        arrange: {
            order: 'asc',
            key: 'appointment_time'
        },
        arrangeDoor: {
            order: 'asc',
            key: 'door_status'
        },
        pay: "Pay",
        yes: 'Yes',
        no: 'No',
        DEVELOPMENT: "development",
        PRODUCTION: "production",
        submit: "Submit",
        cancel: 'Cancel',
        confirm: 'Confirm',
        proceed: 'Proceed',
        changeTheme: 'Change Theme',
        edit: 'Edit',
        delete: 'Delete',
        assign: 'Assign',
        disable: 'Disable',
        carrierSubUserLimitExceed: 'User limit exceed ! Only two users can be active at a time.',
        enable: 'Enable',
        update: 'Update',
        save: 'Save',
        close: "Close",
        viewAllStr: 'View All',
        viewDetailsStr: 'View Details',
        notificationsTitle: 'Notifications',
        view: 'View',
        MM_DD_YYYY_dateFormat: "MM/DD/YYYY",
        MM_DD_YYYY_dateFormat_with_Space: "MM/DD/YYYY ",
        YYYY_MM_DD_T_HH_mm_ss: 'YYYY-MM-DDTHH:mm:ss',
        YYYY_MM_DD: 'YYYY-MM-DD',
        MMMM_DD_YYYY: "MMMM DD YYYY",
        HH_MM_dateFormat: "HH:MM",
        HH_mm_dateFormat: "HH:mm",
        hh_mm_ss_dateFormat: 'hh:mm:ss',
        hh_mm_A_dateFormat: 'h:mm A',
        MM_DD_YYYY__HH_mm: 'MM/DD/YYYY HH:mm',
        MM_DD_YYYY__HH_mm_ss: 'MM-DD-YYYY HH:mm:ss',
        MMMM_Do_YYYY_h_mm_ss_a: "MMM DD, YYYY HH:mm",
        yyyy_mm_dd_HH_MM_ss:"YYYY-MM-DD HH:mm:ss",
        MMM_DD_YYYY_h_mmA: 'MMM DD YYYY h:mmA',
        MMMM_Do: "MMM DD",
        MMMM_DD_YYYY: "MMMM DD YYYY",
        datetimelocal: "yyyy-MM-DDThh:mm",
        Do_MMMM_YYYY: 'Do MMMM YYYY',
        MMMM_Do_YYYY: 'MMMM Do YYYY',
        resendEmail: 'Resend Email',
        resetPassword: 'Reset Password',
        emailAccess: 'Email Access',
        Password: 'Password',
        schedule: 'Schedule',
        reschedule: "Reschedule",
        completed: "Completed",
        appointments: 'Great News! We found your Purchase Order ',
        failure: 'Failure',
        next: 'Next',
        subscribe: 'Subscribe',
        noContinue: 'No, continue with FreightSmith',
        yesCancel: 'Yes, cancel my subscription',
        welcome: 'Welcome',
        freightSmith: 'FreightSmith',
        recommendations: 'Recommendations',
        errorLengthValidation: 'This value cannot be lesser than ',
        loremIpsumDummyText: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        selectWarehouse: "Select Warehouse",
        selectCarrier: "Select Carrier",
        select: 'Select',
        filterByWarehouse: 'Filter By Warehouse',
        filterByCarrier: 'Filter By Carrier',
        areYouSure: "Are you sure you want to",
        renew: 'Renew',
        phoneCustomError: 'Length should be equal to 10.',
        loadTitle:'Load',
        inBoundLoadTitle: 'Inbound Load',
        otherLoad: 'Other Load',
        allLoad: 'All Load',
        allStr:'all',
        appointmentSuccessMsg:'Your appointment has been scheduled successfully',
        carrierStr:'CARRIER',
        cancelApptTitle:'Cancel Appointment',
        defaultDock: "Make Default Dock",
        updatedOn:"UpdatedOn",
        businessEmail:"techsupport@eclipseia.com",
        SMSAccess:"SMSAccess",
        hypen:'-',
        share: "Share",
        spro: 'Schedule Pro',
        tt: 'Turn Time',
        dockType: 'Dock Type',
        langType: 'Select Language'
    },
    toast: {
        type: {
            info: 'info',
            error: 'error',
            success: 'success',
            warn: 'warn',
        },
        heading: {
            information: 'Information',
            success: 'Success',
            warning: 'Warning',
            error: 'Error'
        }
    },
    comments: {
        commentsTitleStr: 'Comments',
        placeholderComments: 'Type Comment Here...',
        addCommentButtonTitle: 'Add',
        cancelCommentButtonTitle: 'Cancel',
        resetbutton: "Reset",
        characterLimitMessage: 'Limit : 500 characters only',
        addCommentsFailure: 'Failed to add comment! Server not responding.'
    },
    downloadSection: {
        downloadTitleStr: "Download",
        pdfDownloadString: "PDF",
        excelDownloadString: "Excel",
    },
    appointmentReportSummary:{
        header:"Appointment Report Summary",
        labels:["Warehouse","Carrier"]
    },
    poManagement: {
        tableHeader: {
            po: 'PO',
            so: 'SO',
            associatedPo: 'Associated PO\'s',
            appointment_time: 'Appt Time',
            carrier: 'Carrier',
            check_in_time: 'Checked In',
            po_status: 'Wrk Sts',
            dock: 'Dock',
            door: 'Door',
            loadType: 'Load Type'
        },
        emptyTable: {
            text1: 'No Data Available',
            text2: 'Please Choose Another Date',
            text3EmptyDock: 'You Must Select a Dock',
            moduleInPorgressStr1: 'Launching by',
            moduleInPorgressStr2: 'and earlier.'

        },
        statusFilters: {
            pending: 'Pending',
            approved: 'Approved',
            staging: 'Staging',
            unloading: 'Unloading',
            pendingApproval: 'Pending Approval',
            awatingDriver: 'Awating Driver',
            doorAssigned: 'Door Assigned',
            dateExpired:'Date Expired'
        },
        poFiltersSection: {
            clearFilters: 'Clear Filters',
            filtersDropDownId: 'filtersDropDownId',
            showDropDownEventKey: 'show.bs.dropdown',
            hideDropDownEventKey: 'hide.bs.dropdown',
            accordionButtonId: 'accordionButtonId',
            chooseDockTitleString: 'Choose Dock',
            chooseStatusTitleString: 'Choose Status',
            purchaseOrdersTitleString: 'Load Management',
            chooseFiltersTitleString: 'Choose Filters',
            poListAllTitleString: 'All',
            poListScheduledTitleString: 'Scheduled',
            poListUnscheduledTitleString: 'Unscheduled',
            poFiltersNotArrivedString: 'Not Arrived',
            poFiltersAppointmentString: 'Appointment',
            poFiltersCheckedInString: 'Checked In',
            poFiltersDoorAssignedString: 'Door Assigned',
            poFiltersUnloadingStartString: 'Unloading Start',
            poFiltersUnloadingEndString: 'Unloading End',
            poFiltersReleasedString: 'Released',
        },
        poApproval: {
            poHeader: 'PO Approval',
            poScheduled: 'Scheduled',
            poScheduledSubText: 'Already Approved',
            poUnscheduled: 'Unscheduled',
            poUnscheduledSubText: 'Select to approve or Reschedule',
            poApprovalBtn: 'Approve',
            poRescheduleBtn: 'Reschedule',
            selectDock: "Please Select Dock",
            onlySelectDock: "Select Dock"
        },
        PO:{
            poDelete: 'Delete PO'
        },
        searchPOSection: {
            poTitleStr: 'PO',
            carrierTitleStr: 'Carrier',
            vendorTitleStr: 'Vendor',
            searchTitleStr: 'Search',
            searchTitleStrPo: 'Search PO',
            searchTitleWarehouseStr: 'Search Warehouse',

        },
        poTooltip: {
            poNumber: "Please enter the customer Purchase Order here.",
            zip: "Please enter the zip code of the facility for the appointment you are trying to schedule.",
        },
        dateExpired:"Can't perform any operation Date is already Expired!",
        poDetails : {
            homeScreenPoName : "Order Number",
            poSoTitle : "PO/SO",
            poNumber : "PO/SO Number",
            createOrder: "Create Order",
            editOrder:"Edit Order",
            facilityZipCode:"Facility Zip Code",
            errorMessage: 'Enter a correct PO/SO',
            downloadPOSOTemplate: 'Download PO/SO Template',
        },
        
    },
    driver: {
        poType: 'Inbound Type',
        dfirstName: "Driver First Name",
        dlastName: "Driver Last Name",
        dPhone: "Driver Phone",
        dLicence: "Driver License",
        shipmentId:"Shipment Id",
        carrierCode:"Carrier Code",
        originLocation:"Origin Location",
        destination:"Destination",
        trailerName:"Trailer Name",
        trailerNumber:"Trailer Number",
        yardLocation:"Yard Location",
        memo:"Memo",
        poStatus:"PO Status",
        checkInNowTitle:'Check In Now',
        checkOutNowTitle:'Check Out Now',
        checkInSuccess:'Check In Successfully',
        checkOutSuccess:'Checked out successfully',
        checkInTimeTitle:'Check In Time',
        checkOutTimeTitle:'Check Out Time',
        trailerTypeTitle: "Trailer Type",
        vendor: 'Vendor',
        comment: 'Comments',
        trailerTypeRadioBtn:{
            Dropped:"Dropped",
            Live: "Live",
            AllTrailer: "All Trailer",
            neither: 'Neither'
        }
    },
    login: {
        loginTitle: "Inbound Management System",
        bothRequired: "Both Email and Password are required !",
        emailIncorrect: "The email you entered is incorrect",
        passwordIncorrect: "The password you entered is incorrect",
        passwordValidation: 'Password is insufficient to fulfill the requirements.',
        email: "Email",
        password: "Password",
        confirm_password: "Confirm Password",
        setPassword: 'Set Password',
        enterEmail: "Enter Email",
        enterPassword: "Enter Password",
        changePassword: 'Change Password',
        loginButtonTitle: "Login",
        forgotPassword: "Forgot Password ?",
        rememberMe: "Remember Me",
        oldPassword: "Old Password",
        newPassword: "New Password",
        updatePassword: "Update Password",
        confirmPassword: "Confirm Password",
        sessionExpiry: 'Session expired, logging out.',
        feedback_text: 'Feedback',
        help_text: 'Help',
        feedback_tooltip: 'Feedback_tooltip',
        help_tooltip: 'Help_tooltip',
        recover: {
            t1: "Reset your password",
            t2: "Enter your registered mail id to send verification code",
            t3: 'Enter the Verification Code sent to you on registered email',
            t4: 'Set a new password',
            recoverButtonTitle: 'Send',
            tryLoginText: "Did you remember your password ?",
            tryLoginButtonTitle: "Try Login",
            passwordInfoTooltip: `<span><b>Password requirements</b></span> </br>
            <span style={{color: 'green'}}>At least 8 characters long</span> </br>
            Must contain a number </br>
            Must contain a special character </br>
            Must contain atleast one uppercase and lowercase </br>
        `
        },
        verficationCodeTitle: "Verification Code",
        newPassword: "New Password",
        confirmNewPassword: "Confirm New Password",
        verficationCodeIncorrect: 'The code should be 6 characters',
        enterVerificationCode: "Enter code",
        enterHere: "Enter Here",
        save: "Save",
        emailFailureTiltle: 'Didn’t recieve the email ?',
        resendCode: 'Click to resend',
        passwordMismatch: "Password doesn’t match. Please enter the same password",
        oldvsnewMismatch: 'New Password cannot be same old Password',
        userChangePassword: 'Password change successfull',
        logout: 'Logging out',
        mainLandingLoaderText: 'Please Wait, while we retrieve warehouse details.',
        poweredBy: 'Powered By:',
        carrierSignUp: 'Carrier Sign Up',
        CarrierRegistration: 'Carrier Registration',
        autoSignInText: 'Please Wait, while we try to Auto sign in',
        alreadyHaveAccount: "Don't have an Account Already?"
    },
    loadManagement: {
        loadheader: 'Load Details',
        loadheadersBackBtn: 'Back',
        purchaseOrderDetails: {
            trailer: 'Trailer',
            tractor: 'Tractor',
            cases: 'Cases',
            vendor_name: 'Vendor Name',
            items: 'Items',
            driver_name: 'Driver Name',
            driver_phone: 'Driver No',
            loadheadersTitle: 'LOAD_HEADER_TITLE',
            loadheadersBackBtn: 'LOAD_HEADER_BACK_BTN'
        },
        statusOverview: {
            statusOverviewTitle: 'Status Overview',
            appointmentTitle: 'Appointment',
            checkinTitle: 'Checked In',
            doorAssignmentTitle: 'Door Assigned',
            unloadingStartTitle: 'Unloading Start',
            unloadingEndTitle: 'Unloading End',
            releasedTitle: 'Released',
            passedStageStr: 'passed',
            currentStageStr: 'current',
            upcomingStageStr: 'upcoming',
            totalDwellTimeTitle: 'Total dwell : ',
        }
    },
    doorManagement: {

        loadheader: 'Assign Door',
        loadSubheader1: 'Click ',
        loadSubheader2: 'Assign ',
        loadSubheader3: 'below, to notify the driver about the door assigned',
        doorAssignment: 'Door Assignment',
        additionalDoorFilterSelectText: 'Select',
        additionalDoorFilterHeadingText: 'Driver will be notified once door is assigned.',
        no_doors_available_title: 'No doors available.',
        doorReleasedSuccessNotification: 'Door released successfully.',
        poNotApprovedText: 'PO must be Checked In prior to Door Assignment',
        truckinReleasedStateText: 'A door can not be assigned at this stage',
        doorType: {
            standard: 'STANDARD',
            highCube: 'HIGH CUBE',
            noDoorAvailable: 'NO AVAILABLE DOOR'
        },
        doorFilters: {
            door_filter_all: 'All',
            door_filter_available: 'Operable',
            door_filter_blocked: 'Blocked',
            door_filter_in_use: 'In Use',
            door_filter_inoperable: 'Inoperable',

        },
        assignDoorText: {
            api_door_status_empty: '',
            api_door_status_available: 'Available',
            api_door_status_available_in_use: 'Available - IN USE ',
            // api_door_status__in_use: ' - IN USE ',
            display_door_status_available: 'Assign',
            display_more_door_status_available: 'Operable',
            api_door_status_occupied: 'Occupied',
            display_door_status_occupied: 'Assigned',
            api_door_status_assigned: 'Assigned',
            display_door_status_assigned: 'Assigned',
            api_door_status_inoperable: 'Inoperable',
            api_door_status_out_of_order: 'Out of Order',
            api_door_status_cleaning: 'Cleaning',
            display_door_status_inoperable: 'Inoperable',
            api_door_status_in_use: 'In Use',
            display_door_status_in_use: 'In Use',
            api_door_status_blocked: 'Blocked',
            api_door_status_blocked_floor: 'Blocked - Floor',
            api_door_status_blocked_shipping_trailer: 'Blocked - Shipping Trailer',
            api_door_status_blocked_backhaul_dropped: 'Blocked - Backhaul/Dropped',
            api_door_status_blocked_backhaul_dropped_in_use: 'Blocked - Backhaul/Dropped - IN USE',
            display_door_status_blocked: 'Blocked',
            api_door_status_not_available: 'Not Available',
            display_door_status_not_available: 'Not Available',
        },
        assignDoorResponseText: {
            alreadyAssigned: 'Truck is already assigned a Door.'
        },
        doorOption: {
            more: 'More',
            changeDoor: 'Change Door',
            releasedDoorTitle: 'Release Door',
            releasedDisabledDoorTitle: 'Released',
            noDoor: {
                door: "",
                door_status: "Not Available",
                door_type: "NO AVAILABLE DOOR",
                className: 'disabled'
            },
            popupAttr: {
                id: 'changeDoorPopup',
                modalLabel: 'changeDoorPopup',
                showClose: false,
                showfooter: true,
                showHeader: true,
                keyboard: false,
                backdrop: 'static',
                title: 'Confirm',
                bodyText: 'Do you want to change the door ?',
                subText: 'Send Driver Notification  ',
                modalSize: '',
                footerBtn: {
                    btn1: {
                        show: true,
                        text: 'Cancel'
                    },
                    btn2: {
                        show: true,
                        text: 'Confirm'
                    }
                }
            },
            popupAttrMore: {
                id: 'changeDoorPopup',
                modalLabel: 'changeDoorPopup',
                showClose: true,
                showfooter: true,
                showHeader: true,
                keyboard: true,
                backdrop: true,
                title: 'Confirm',
                bodyText: 'View all doors?',
                footerBtn: {
                    btn1: {
                        show: true,
                        text: 'No'
                    },
                    btn2: {
                        show: true,
                        text: 'Yes'
                    }
                }
            },
            popupReleaseDoorAttr: {
                id: 'changeDoorPopup',
                modalLabel: 'changeDoorPopup',
                showClose: true,
                showfooter: true,
                showHeader: true,
                keyboard: true,
                backdrop: true,
                title: 'Confirm',
                bodyText: 'Do you want to release the assigned door ?',
                modalSize: '',
                footerBtn: {
                    btn1: {
                        show: true,
                        text: 'No'
                    },
                    btn2: {
                        show: true,
                        text: 'Yes'
                    }
                }
            },
            doorStatusId: {
                INOPERABLE: '21357',
                FLOOR_BLOCKED: '21358',
                SHIPPING_TRAILER: '21360',
                BACKHAUL_DROPPED: '21361',
                Available: '22126',
                Cleaning: '22127',
                Assigned: '22128',
                OutofOrder: '22129',
                Blocked: '22130',
            }
        }
    },
    poStatus: {
        APPROVING: "Approving",
        RESCHEDULING: "Rescheduling",
        PENDING: "Pending"
    },
    dock: {
        DOCK: "Dock",
        CHOOSE_DOCK: "Choose Dock",
        SELECT_DOCK: "Select Dock",
        operational_daysTitle:"Operational Days",
        dockToolTip:{
            operational_daysTooltip:'Green - Operational, Red - Non- Operational'
        },
        dockAlert: {
            emailAccessWarning: 'Atleast one user should have an email access',
        }
    },
    dataTestId: {
        tableCheckAll: 'CHECK_ALL',
        tableLoaders: 'TABLE_LOADER',
        emptyList: 'EMPTY_LIST',
        dateSelection: "DATE_SELECTION",
        dayLabel: "DAY_LABEL",
        calenderIcon: "CALENDER_ICON",
        dateLabel: "DATE_LABEL",
        removeFilterButtonTestId: 'removeFilterButton',
        loadheadersTitle: 'LOAD_HEADER_TITLE',
        loadheadersBackBtn: 'LOAD_HEADER_BACK_BTN',
        logoContainer: "logoContainer",
        title: "title",
        customInputTitle: "customInputTitle",
        customInput: "customInput",
        passwordInputTitle: "passwordInputTitle",
        passwordInput: "passwordInput",
        rememberMeCheckBox: "rememberMeCheckBox",
        rememberText: "rememberText",
        customButton: "customButton",
        forgotPasswordText: "forgotPasswordText",
        feedback: "feedback",
        help: "help",
        poApprovalHeaderText: 'PO_APPROVAL_HEADER_TEXT',
        infoIcon: "infoIcon",
        timer_banner_data: "timer_banner_data",
        timer_data: "timer_data",
        operational_days:"operational_days",
        facility_closer:"facility_closer",
        validationError:'validation_error',
        closeBtn:'close_btn',
        adjustableTextComponent:"adjustable_text_component",
        textToggle:"text_toggle",
        fileUpload:"file_upload",
        loading:"loading",
        image:"image",
        fileUploadInput:"file_upload_input",
        commonCheckBox:"common_check_box",
        commonTooltip:"common_tooltip",
        commonDropDown:"common_dropdown",
        passwordEyeButton:"password_eye_button",
        customDynamicForm:"custom_dynamic_form",
        titleIcon:"title_icon",
        customPagination:"custom_pagination",
        header:"header",
        popup:"popup",
    },
    notifications: {
        login: {
            recoverEmailSent: "The verification code is been sent to your registered email. This might take up to 1-2 minutes to reach your mailbox. Thank you for waiting.",
            wentWrong: "Something went wrong, try again later.",
            blankEmail: "Email cannot be blank.",
            blankPassword: "Password cannot be blank.",
            passConformPassCheck: "Password doesn't match,Please enter the same password",
            passwordResetSuccess: 'Password reset successful',
            verificationCodeMismatch: "Invalid Verification code, Please enter correct Verification code",
            verficationCodeBlank: 'Verification code cannot be blank',
            followInitialLoginStep: 'Your email and password access details have been sent to you to your registered email. Kindly check your inbox or spam folders. Search for "Welcome to Freightsmith" email which contains your temporary password for your initial log. For assistance contact: 1 (855) 752-2878 / support@freightsmith.net',

        },
        dataAddedSuccess: "Success",
        help: {
            help: 'Issue got registered.We will get back to you',
            feedback: 'Thank you for your valuable feedback',
            errorExceeded: "More than 2 image not allowed"
        }

    },
    generalError: {
        lengthGreater: "length is greater than required length ",
        lengthLess: "length is smaller than required length ",
        lengthBet: "length should be in between ",
        lengthequal: "length should be equal to "
    },
    dashboard: {
        default: 'Default',
        custom: 'Custom',
        defaultdashboard: {
            heading: 'Load Analytics',
            unloadingHours: {
                heading: 'Unloading Hours',
                avgTimeIndicatorText: 'Avg. Time',
                subHeadingTruck: 'Trucks/Hour - Average',
                subHeadingPallet: 'Pallets/Hour - Average',
            },
            detentionStatus: {
                heading: 'Detention Status',
                subHeading: 'Based upon two hours from appointment time'
            },
            appointmentCompliance: {
                heading: 'Appointment Compliance',
            },
            top10LateLoads: {
                heading: 'Top 10 Late Loads',
                vendorHeading: 'Vendor',
                carrierHeading: 'Carrier',
                always: 'Always',
                sometimes: 'Sometimes',
                firstTime: '1st Time',
                lateToolTipParentText: 'Last 90 Days (total trucks within the 90 days)',
                alwaysLateTooltip: '75% to 100%.',
                sometimesLateTooltip: '2% to74%.',
                firstTiemLateTooltip: '1st time.'
            },
            unloadingTypeSummary: {
                heading: 'Unloading Type Summary',
            },
            doorUsageSummary: {
                heading: 'Door Usage Summary',
                trucksHeading: 'Trucks',
                palletsHeading: 'Pallets'

            },
            funnelGraph: {
                sectionTitle: {
                    loadStatusOverviewTitle: 'Load Status Overview',
                    allLoadsButtonTitle: 'All Loads',
                    lateLoadsButtonTitle: 'Late Loads',
                    taskItemDueTitle: 'Task Item Due',
                    facilityRatings: "Facility Ratings",
                    userRatings: "User Ratings",
                    currentMonth: "Current Month"
                },
                titleStrings: {
                    appointmentTitle: 'Not Arrived',
                    staggingDwellTitle: 'Staging Dwell',
                    dockingDwellTitle: 'Docking Dwell',
                    unloadingDwellTitle: 'Unloading Dwell',
                    releasedTitle: 'Released',
                    paperworkDwellTitle: 'Paperwork Dwell',
                    backhaulsAndDrops: "*Includes Backhauls & Drops",
                    liveloads: "Live Loads"
                },
                taskItemDueRows: {
                    unschedulePoTitle: 'Unscheduled POs pending approval',
                    schedulePoTitle: 'Scheduled POs approved',
                    staggingTrucksTitle: 'Trucks in staging',
                    awaitDriveRespTitle: "Notifications awaiting driver's response",
                    trucksAssignedTitle: 'Trucks assigned to door',
                    unloadTrucksTitle: 'Trucks are unloading',
                    awatingPaperworkDwell: 'Trucks awaiting paperwork',
                    inDetention: 'Trucks in detention',
                    approachingDetention: 'Trucks nearing detention',
                    taskItemsLoadManagementHeaders: {
                        taskItemTitle: 'Task Item :'
                    }
                },
                ratings: {
                    okay: "Okay",
                    good: "Good",
                    great: "Great"
                }
            },
            doorStatus: {
                heading: 'Live Door Status',
                labels : ["Available", "Occupied" ,"Blocked", "Blocked - Backhaul/Dropped", "Blocked - Floor", "Blocked - Shipping Trailer", "Cleaning", "Out of Order", "Inoperable",]
            },
            unscheduledPos: {
                heading: "Unscheduled Po's",
            },
            doorAssignment: {
                heading:  'Door Assignment'
            }
        },
        customDashboard: {
            addSectionBtnText: 'Add a section',
            selectGraphTitle: 'Select Graph',
            top10LateLoads: {
                tableHeaderTitle: {
                    serial_num: 'S.No',
                    po: 'PO',
                    appointment_time: 'Appt',
                    check_in_time: 'Checked In',
                    vendor: 'Vendor',
                    carrier: 'Carrier',
                    late_record: 'Late Record',
                    fs_score_title: 'FS Score'

                }
            }
        }
    },
    navBar: {
        analytics: 'Analytics',
        loadManagement: 'Load Management'
    },
    allRoutes: {
        unloadinghours: {
            path: 'unloadinghours',
            storeObject: 'unloadingHoursdrillDownReport'
        },
        loadstatus: {
            path: 'loadstatus',
            storeObject: 'dashboardLoadStatusDrillDownReport'
        },
        appointmentcompliance: {
            path: 'appointmentcompliance',
            storeObject: 'appointmentCompliancesDrillDownReport'
        },
        detentionstatus: {
            path: 'detentionstatus',
            storeObject: 'detentionStatusDrillDownReport'
        },
        unloadingTypeSummary: {
            path: 'unloadingtypesummary',
            storeObject: 'unloadingTypeSummaryDrilldown'
        },
        doorUsageSummary: {
            path: 'doorusagesummary',
            storeObject: 'doorUsageSummaryDrillDown'
        },
        doorstatus: {
            path: 'doorstatus',
            storeObject: 'doorStatusDrillDown'
        },
        top10LateLoad: {
            path: 'toplateloads',
            storeObject: 'top10LateLoadsDrillDown'
        },
        unscheduledpo: {
            path: 'unscheduledpo',
            storeObject: 'unScheduledPOSummaryDrillDown'
        },
        user: {
            path: 'user',
            storeObject: 'userList'
        },
        userAssign: {
            path: 'userAssign',
            storeObject: 'userRoleDetailsList'
        },
        superAdmin: {
            path: 'superAdmin',
            storeObject: 'rolesList'
        },
        companyGroup: {
            path: 'companyGroup',
            storeObject: 'companyGroupList'
        },
        companyList: {
            path: 'companyList',
            storeObject: 'companyList'
        },
        faqAdminList: {
            path: 'faqAdminList',
            storeObject: 'faqList'
        },
        feedbackAdminList: {
            path: 'feedbackAdminList',
            storeObject: 'feedbackList'
        },
        issuesAdminList: {
            path: 'issuesAdminList',
            storeObject: 'issuesList'
        },
        warehouseAdminList: {
            path: 'warehouseAdmin',
            storeObject: 'data'
        },
        poSSList: {
            path: 'poSSList',
            storeObject: 'poSSArr'
        },
        poSSResponse: {
            path: 'poSSResponse',
            storeObject: 'poSSResponse'
        },
        allApptsListObj: {
            path: 'allApptsList',
            storeObject: 'myAppointmentsList'
        },
        whMetaDocksObj: {
            path: 'whMetaDocksArr',
            storeObject: 'whMetaDocksList'
        },
        whMetaLeavesObj: {
            path: 'whMetaLeavesArr',
            storeObject: 'whMetaLeavesList'
        },
        whMetaBreaksObj: {
            path: 'whMetaBreaksArr',
            storeObject: 'whMetaBreaksList'
        },
        whMetaTimeslotObj: {
            path: 'whMetaTimeslotArr',
            storeObject: 'whMetaTimeslotList'
        },
        financeReportObj: {
            path: 'financeReportObj',
            storeObject: 'ssFinanceReport'
        },
        userRatingsObj: {
            path: 'usesrRatingsObj',
            storeObject: 'userRatingsReportList'
        },
        scheduleUploadObj: {
            path: 'scheduleUploadObj',
            storeObject: 'scheduleUploadData'
        },
        fsPayPOHistory: {
            path: 'fsPayPoHistoryObj',
            storeObject: 'fsPayPOHistoryList'
        }
    },
    help: {
        createTextForHelp: "Create Help & Support",
        createTextForFeedback: "Create Application Feedback",
        titleTextForHelp: "Help & Support",
        titleTextForFeedback: "Application Feedback",
        heroTextForTechnicalSupport: "For Customer or Technical support contact",
        heroTextForApplicationFeedback: "For Application Feedback or Request contact",
        helpEmailCustAndTech: "support@freightsmith.net",
        helpPhoneCustAndTech: "1-855-752-2878",
        helpEmailApplicationFeedback: "itdev@uchaininc.com",
        helpPhoneApplicationFeedback: "720-762-9068",
        emailIncorrect: "The email you entered is incorrect",
        contactIncorrect: "The contact you entered is incorrect, please use (NNN) NNN - NNNN.",
        confirmPasswordIncorrect: "The password needs to match with confirm password.",
        passwordIncorrect: "The needs to have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character.",
        required: "This field is required",
        name: "Full Name",
        enterName: "Enter Full Name",
        email: "Email",
        phone: "Phone",
        enterEmail: "Enter Email",
        enterPhone: "Enter Phone",
        issue: "Issue(500 Characters)",
        feedback: "Feedback(500 Characters)",
        enterIssue: "Enter here",
        formName: "Help",
        helpButtonTitle: "Send",
        lengthExceeded: "500 Characters length exceeded",
        imageTypen: "Format: JPG, PNG",
        lengthExceeded: "500 Characters length exceeded",
        imageSizeConfig: "Max. 2 files, 10MB each, Format: JPG, PNG & PDF",
        onlyNumberAllowed: "Only number between 0-9 is allowed",
        onlyTextAllowed: "Only Text allowed"
    },
    helpSupportFaq: {
        faqTitle: "FAQ's",
        questionStrTitle: 'Question :',
        answerStrTitle: 'Answer :',
        creatFaqButtonTitle: "Create FAQ's",
        editFaqButtonTitle: "Edit FAQ's",
        appTypeText1:{
            key:'IMS',
            value:'IMS'
        },
        appTypeText2:{
            key:'SchedulePro',
            value:'SP'
        },
        tableHeaders: {
            serialNum: 'S.No',
            questionsTitle: 'Questions',
            answersTitle: 'Answers',
            createdOnTitle: 'Created On',
            statusTitle: 'Status',
            appType:'App Type'
        },
        alerts: {
            updatedSuccessfully: 'FAQ updated successfully.',
            createdSuccessfully: 'FAQ created successfully.',
            cuIdError: 'Unable to find User Id.',
        }
    },
    requestsReceived: {
        requestsReceivedTitle: 'Requests Received',
        feedbackTitle: 'Feedback',
        issuesTitle: 'Issues',
        tableHeaders: {
            issueTitleStr: 'Issue',
            feedback: 'Feedback',
            images: 'Images',
            processedBy: 'Processed By',
            action: 'Action'
        },
        alerts: {
            updatedSuccessfully: 'Updated successfully.',
            updateError: 'Unable to update.',
        },
    },
    userPermission: {
        usertypes: {
            superAdmin: 'SUPERADMIN',
            user: 'USER',
            carrier: 'CARRIER'
        },
        adminRoles: {
            ccAdmin: 'CCADMIN'
       },
        superwarehouse: 'SUPERWAREHOUSE',
        emptyUserWarehouseList: "You don't have access to any Warehouse, kindly raise access request.",
        warehouseListDropDownTitle: 'Select a warehouse to proceed :',
        selectWarehouse: 'Select Warehouse',
        selectWarehouseSubText: 'Select a warehouse to proceed further',
        warehouseListDropDownDefualt: 'Select Warehouse',
        userPermissionNoData: 'No data found',
        toast: {
            userTypeFail: 'Error in fetching User Type, Kindly contact your administrator',
            userPermissionFail: 'Unable to get permission, logging out.',
            userAccountDetailsFail: 'Error in fetching User details, Kindly contact your administrator',
        }
    },
    user: {
        createBtnTitle: 'Create user',
        userAsssignRoleBtnTitle: 'Assign Access',
        heading: 'Users',
        popup: {
            createUser: {
                title: 'Create User',
                phone_placeHolder: '(NNN) NNN - NNNN',
                fields: {
                    given_name_title: 'First Name',
                    family_name_title: 'Last Name',
                    phone_number_title: 'Phone Number',
                    user_name: 'Email',
                },
            },
            editUser: {
                title: 'Edit User'
            }
        },
        error: {
            message: {
                email: 'Enter a valid email',
                phone: 'Enter a valid phone number'
            }
        },
        toast: {
            blankForm: 'Kindly fill the form',
            createUserSuccess: 'User created successfully',
            updateUserSuccess: 'User updated successfully',
            createUserFailure: 'Unable to create user',
            updateUserRoleFailed: 'Unable to assign role',
            editUserRoleFailed: 'Unable to update',

        },
        carrier:{
            type:{
                PRIMARY:"PRIMARY",
                SECONDARY:"SECONDARY",
            },
            errors:{
                userDeactivated:'Your account has been disabled. Please contact the administrator for more information.'
            }
        }
    },
    superUserAdmin: {
        city: 'City',
        company: {
            companyAdminTitle: 'Company Admin',
            createCompanyTitle: 'Create Company',
            editCompanyTitle: 'Edit Company',
            getCompanyErrorSting: 'Unable to get companies',
            placeholderIconLink: 'https://freightsmit-fileupload-bucket-dkg-dev.s3.us-west-2.amazonaws.com/company-placeholder.png',
            createCompanyForm: {
                companyNameTitleStr: 'Company Name',
                regionCode: "Locale",
                contact: "Contact",
                createdCompanySuccessfully: 'Created company successfully.',
            },
            errorMessages: {
                updateFailure: 'Unable to update. Try again later.'
            }
        },
        companyGroup: {
            companyGroupAdminTitle: 'Company Group Admin',
            createCompanyGroupTitle: 'Create Company Group',
            editCompanyGroupTitle: 'Edit Company Group',
            updatedSuccessResponse: 'Updated successfully.',
            createCompanyGroupForm: {
                companyGroupNameTitleStr: 'Group Name',
                regionCode: "Locale",
                contact: "Contact",
            },
            tableHeader: {
                serialNum: 'S.No',
                logo: 'Logo',
                name: 'Name',
                CompanyType: 'Company Type',
                createdOn: 'Created On',
                apptTime: 'Appt Time',
                contactNum: 'Contact',
                email: 'Email',
                status: 'Status',
                actions: 'Actions',
                placeholderIconLink: 'https://freightsmit-fileupload-bucket-dkg-dev.s3.us-west-2.amazonaws.com/neural.png',
            }
        },
        warehouseAdmin: {
            preCheckBockerMsg:'You cannot alter this as there are few appointments already sheduled. Click View Detail to manage the appointment(s).',
            preCheckBlockerMsgForDockConflict:'Kindly manage scheduled appointments before proceeding to delete timeslots.',
            warehouseAdminTitle: 'Warehouse Admin',
            imageSizeConfig: "Select a file of at-most 10MB, Format: JPG or PNG",
            warehouseTable: {
                serialNumColTitle: 'S.No',
                nameColTitle: 'Name',
                idColTitle: 'ID',
                companyGroupColTitle: 'Company Group',
                companyColTitle: 'Company',
                statusColTitle: 'Status',
                actionsColTitle: 'Actions',
                logoColTitle: 'Logo',
            },
            buttonTitles: {
                createWarehouseButtonTitle: 'Create Warehouse',
                editWarehouseButtonTitle: 'Edit Warehouse',
                editButtonTitle: 'Edit',
                statusButtonTitle: 'Status',
                deleteButtonTitle: 'Delete'
            },
            createWarehouseForm: {
                companyGroup: 'Company Group ',
                imageLogo: "Upload Logo",
                imageback: "Upload Background Image",
                company: "Company",
                lumpingFaciltyId: "Lumping Facility Id",
                warehouseNameTitleStr: 'Warehouse Name',
                warehouseDateActivated: "Warehouse Date Activation",
                idTaxCode: "TaxCode Id",
                regionCode: "Region Code",
                controlPrefix: "Control Prefix",
                timezone: "Time Zone",
                locationName: "Location Name",
                formRequirement: 'Form Requirement',
                address: "Address",
                city: "City",
                zip: "Zip Code",
                state: "State",
                country: "Country",
                subdomain: "Sub Domain",
                enterHerePlaceHolder: 'Enter here',
                addressTitleStr: 'Address',
                cityTitleStr: 'City',
                zipCodeTitleStr: 'Zip Code',
                selectStateTitleStr: 'Select State',
                selectCountryTitleStr: 'Select Country',
                enabledTitleStr: 'Enabled',
                selectLogoTitleStr: 'Select a logo : ',
                saveButtonTitle: 'Save',
                selectCompanyGroupTitle: 'Select Company Group ',
                selectCompanyTitle: 'Select Company ',
                selectWarehouseTitle: 'Select Warehouse ',
                selectRolesTitle: 'Select Roles ',
                sEmail: "Support Email",
                branding: "Branding",
                zipCodeErrorMessage: 'Please enter your correct zip code',
                contactNumErrorMessage: 'Please enter contact number correctly ',
                warehouseType: 'Warehouse Type',
                whTypeIms: 'IMS',
                whTypeSS: 'SS',
                whTypeBoth: 'BOTH',

            }
        },
        rolesAdmin: {
            rolesAdminTitle: 'Roles Admin',
            buttonTitles: {
                createRolesButtonTitle: 'Create Role',
                editRolesButtonTitle: 'Edit Role',
                editRolesButtonTitle: 'Edit Role'
            },

            toast: {
                blankForm: 'Kindly fill the form',
                createRoleSuccess: 'Role created successfully',
                updateRoleSuccess: 'Role updated successfully',
                createRoleFailure: 'Unable to create role',
                duplicateRoleFailure: 'Roles cannot be duplicate',
                updateRoleRoleFailed: 'Unable to update role',
                noDataAvailable: 'Unable to retrieve data',
                blankError: 'Role name & description cannot be empty'
            },
            createRolesForm: {
                roleNameTitleStr: 'Role Name',
                roleDescriptionTitleStr: 'Role Description',
                enterHerePlaceHolder: 'Enter here',
                enabledTitleStr: 'Enabled',
                saveButtonTitle: 'Save'
            }
        },
        rolesFeaturesAdmin: {
            rolesAdminTitle: 'Roles Features Admin',
            buttonTitles: {
                createRolesFeatureButtonTitle: 'Add Role Features',
            },
            createRolesForm: {
                roleNameTitleStr: 'Role Feature',
                roleDescriptionTitleStr: 'Role Feature Description',
                enterHerePlaceHolder: 'Enter here',
                enabledTitleStr: 'Enabled',
                saveButtonTitle: 'Save'
            },
            addFeatures: {
                feature: 'Feature'
            },
            tableHeadTitle: {
                serialNo: 'S.No',
                feature: 'Feature',
                pageAccess: 'Page Access',
                navigation: 'Navigation',
                task: 'Task',
                access: 'Access',
            },
            toast: {
                noDataSelected: 'Please alter a page or task access.'
            }
        }

    },
    settings: {
        heading: 'Settings',
        dropdownMenu: {
            theme: 'Change Theme',
            accountSettings: 'Account Settings',
            logout: 'Logout',
            helpAndFeedback:'Help And Feedback'
        },
        accountSettings: {
            personalInformation: 'Personal Information',
            security: 'Security',
        },
        logoutPopupAttr: {
            id: 'logoutPopup',
            modalLabel: 'logoutPopup',
            showClose: true,
            showfooter: true,
            showHeader: true,
            keyboard: true,
            backdrop: true,
            alignType: 'center',
            type: 'info',
            title: 'Confirm',
            bodyText: 'Are you sure you want to logout?',
            subText: '',
            modalSize: '',
            footerBtn: {
                btn1: {
                    show: true,
                    text: 'No'
                },
                btn2: {
                    show: true,
                    text: 'Yes'
                }
            }
        },
        userAccountTitle: {
            location: 'Location',
            role: 'Role',
            email: 'Email',
            phone: 'Contact No.',
            changeProfilePic: 'Change Image'
        }
    },
    reports: {
        selectReportTitleString: 'Please select any one of the report.',
        generateReportButtonTitle: 'Generate Report',
        heading: 'Reports',
        master_id_all: '00000000-0000-0000-0000-000000000000',
        truckUnloadSummaryReport: {
            title: 'Truck Unload Summary Report',
            modifyReport: 'Modify Report',
            createReport: 'Generate Truck Unload Summary Report',
            selectLumpingFacility: "Unloading Facility",
            selectTimePeriod: 'Time period',
            selectDate: 'Select Date',
            warehouse: 'Warehouse',
            tableHeaders: {
                dockNameStr: 'Dock',
                trucksUnloadedStr: 'Trucks Unloads',
                truckUnloadedPctVendorStr: 'Trucks Unloaded % Vendor',
                endPallets: 'End Pallets',
                palletsPerHour: 'Pallets Per Hour',
                avgUnloadTime: 'Avg. Unload Time',
                loadsWithUnloadsDelay: 'Loads - Unloads Delay',
                avgCheckOutTime: 'Avg. Check Out Time',
                loadsWithCheckOutDelay: 'Loads w/Check Out Delay',
                loadsArrivedLate: 'Loads Arrived Late',
                avgDoorAssignedToUnloadFinish: 'Avg. Door Assign To Unload Finish',
                avgDoorAssignedToBillsIn: 'Avg. Door Assign To Bills In',
                avgBillsInToUnloadStart: 'Avg. Bills in to Unload Start',

                avgApptToBillsOut: 'Avg. Appt to Bills Out',
                avgBillsInToBillsToDriver: 'Avg. Bills In to Bills To Driver',
                avgBillsOutToBillsToDriver: 'Avg. Bills Out to Bills To Driver',
                avgArrivalsToBillsToDriver: 'Avg. Arrivals To Bills To Driver',
                avgBillsInToLumpStart: 'Avg. Bills In to Lump Start',
                avgApptToBillsOutToDriver: 'Avg. Appt to Bills Out to Driver',
            }
        },
        adfatReport: {
            title: "ADFAT",
            titleReportFullTitle: 'Active Door Find A Truck Report',
            modifyReport: 'Modify Report',
            createReport: 'Generate ADFAT Report',
            selectLumpingFacility: "Select Lumping Facility",
            selectTimePeriod: ' time period',
            selectDate: 'Select Date',
            tableHeaders: {
                truckUnloadedStr: 'Trucks Unloaded',
                truckUnloadedPctVendorStr: 'Truck Unloaded Pct-Vendor',
                truckUnloadedPctVendorStr: 'Truck Unloaded Pct-Vendor',
            },
            dropdownLabels: {
                reportType: 'Report Type',
                lumpingCompany: 'Unloading Company',
                dock: 'Dock',
                vendor: 'Vendor',
                startDate: 'Start Date',
                endDate: 'End Date',
                billedTo: 'Billed To',
                secondaryGrpBy: 'Secondary Group By',
                trailer: 'Trailer',
                authNum: 'Authorization Number',
                controlNum: 'Control',
                longLumpTime: 'Long Unload Time Threshold (in minutes)',
                longFacTime: 'Long Facility Time (in minutes)',
                doorAssgnToBillsOut: 'Door Assigned to Bills Out (in minutes)',
                apptToBillsToDriver: 'Appointment To Bills to Driver Threshold (in minutes)',
                droppedAndBackhaul: 'Exclude Dropped & Backhaul ',
                warehouse: 'Warehouse',
                carrier: 'Carrier',
                appointment: 'Appointment',
                primaryGrpBy: 'Primary Group By',
                tertiaryGrpBy: 'Tertiary Group By',
                tractor: 'Tractor',
                po: 'PO',
                lateAppintThersh: 'Late for Appointment Threshold (in minutes)',
                longPaperworkTime: 'Long Paperwork Time (in minutes)',
                longLumpendToBillsOutThersh: 'Long Unload End to Bills Out Threshold (in minutes)',
                delayDetention: 'Delay Detention 2 hrs'
            },
            reportHeadingTitle: {
                date: 'Date',
                dock: 'Dock',
                door: 'Door',
                carrier: 'Carrier',
                po: 'PO',
                vendor: 'Vendor',
                count: 'Count',
                cases: 'Cases',
                startEndChep: 'Start/End/Chep',
                scheduled: 'Scheduled',
                arrival: 'Arrival',
                checkIn: 'Check In',
                doorAssign: 'Door Assign',
                lumpStart: 'Unload Start',
                lumpEnd: 'Unload End',
                billsOut: 'Bills Out',
                activeDoor: 'Active Door',
                unload: 'Unload',
                paperWork: 'Paperwork',
                detention: 'Detention',

            },
            reportParentHeadingTitle: {
                dateAndLocation: ' Date & Location',
                loadInformation: 'Load Information',
                countAndBreakdown: 'Counts & Breakdown',
                actualTime: 'Actual Times',
                elapsedTime: ' Elapsed Times'
            }
        },
        truckUnloadStatisticsReport: {
            title: 'Truck Unload Statistics',
            createReport: 'Generate Truck Unload Statistics Report',
            selectDate: 'Select Weekending',
            selectDock: 'Dock',
        },
        scheduledLoadStatus: {
            createReport: 'Generate Scheduled Load Status Report',
            selectBillingCode: 'Billing Type',
            scheduledLoadStatusTitle: 'Scheduled Load Status',
            tableHeaders: {
                billingCode: 'Billing Type',
                scheduledCarrier: 'Scheduled Carrier',
                carrierName: 'Carrier Name',
                scheduledTime: 'Scheduled Time',
                checkInTime: 'Check In Time',
                pos: "PO's"
            }
        },
        ssFinanceReport: {
            finanaceReport: "Finance Report",
            carrier: 'Carrier',
            mcr: 'MCR#',
            firstName: 'First Name',
            lastName: 'Last Name',
            date: 'Start date of subscription',
            approvalCode: 'Approval Code',
            tansID: 'Transaction ID',
            accNum: 'Account Number',
            amount: 'Amount($)',
            S_NO: "S NO"
        },
        facilityRatings: {
            startGreaterThanEndError: 'Start Date cannot be greater than End date',
            endGreaterThanStartError: 'End Date cannot be less than Start date',
            dateDiffGreaterThan12Months: 'Date range must be 3 months or less.',
            graphLoaderText: 'Please wait while we generate Facility Rating Graph:'
        },
        userRatings: {
            startGreaterThanEndError: 'Start Date cannot be greater than End date',
            endGreaterThanStartError: 'End Date cannot be less than Start date',
            dateDiffGreaterThan12Months: 'Date range must be 3 months or less.',
            graphLoaderText: 'Please wait while we generate User Rating Graph:'
        },
        gaurdReport: {
            checkin: 'Check In',
            checkout: 'Check Out',
        }
    },

    smartScheduler: {
        apptDetailsStr:'Appointment Details',
        carrierSignUp: {
            backgroundImageLink: 'https://freightsmith-ims-logo.s3.us-west-2.amazonaws.com/FreightSmith_LOGO_Inbound_Management_Systems.png',
            metaData: {
                monthlySubscription: 'Monthly Subscription of $25',
            },
            hiTitle: 'Hi',
            signUpMessage1: 'Thank you for signing up with',
            scheduleError: 'A Timeslot must be created by Schedule Admin to schedule an Appointment. Please contact your Schedule Administrator.',
            signUpMessage2: 'Please wait, while we are setting up things for you.',
            conditionMessage1: "* If the price changes, we'll notify you beforehand.",
            conditionMessage2: "** You can check you renewal date or cancel anytime via you",
            priceTitle: '$25/month',
            haveAccountAlready: 'Have an account already ?',
            loginHere: 'Login here.',
            tnC: 'Terms & Conditions',
            accountPage: 'Account page',
            completeSignUpIntro1: 'To complete the sign up, pay subscription charges',
            introText1: "FreightSmith SchedulePro is the ultimate solution for streamlining and simplifying warehouse appointment scheduling",
            createAccountTitle: 'Create your account now',
            signUpAgainMessage: 'please sign up again',
            deleteUserError: 'Error deleting user',
            savingUserDataError: 'Unable to save user data.',
            registerUserError: 'Unable to register user.',
            signOutConfirmMessage: 'Are you sure you want to sign out?',
            paytrace: {
                paymentFailureMessage: 'Unable to process payment - ',
                paymentSuccessMessage: 'Payment is successfull.',
                initPaytraceError: 'Unable to contact Paytrace, ',
                cardValidationError: 'Unable to process card details,',
                paymentWarning1: 'Payment is processing. Please do not reload or close this page.',
                paymentWarning2: 'Please wait, payment is processing...',
                updateCardText: 'Please wait, request is processing...',
                updateCardSuccessText: 'Card updated successfully.',
            },
            carrierFormTitles: {
                mcrCodeTitle: 'MC #',
                paymentMethod: 'Payment Method',
                signUpSuccessfull: 'Signed Up Successfully.',
                signUpError: 'Unable to sign up, '
            },
            creditCardForm: {
                cardNumber: 'Credit Card Number',
                cardExpirationDate: 'Credit Card Expiration Date',
                cscSecurityCode: 'Credit Card CSC (Security Code)',
            }
        },
        addMorePoPopupText: {
            title: 'Confirmation box',
            body: "Do you want to add more PO/SO's ?",
            addMorePoError: 'Same PO/SO cannot be added as an add-on',
            poAddedSuccessfully: 'PO/SO added successfully',
            poRemovedSuccessfully: 'PO/SO removed successfully'
        },
        uploadAppointmentDocs: {
            title: 'Upload Documents',
            body: "Do you have any documents to attach with this appointment ?",
            documentAddedSuccessfully: 'Document added successfully',
            documentRemovedSuccessfully: 'Document removed successfully',
            noUploadDocsTitle: 'No documents uploaded yet.',
            docsDisclaimerText: 'Note - File size limit: 20MB. Supported formats: docx, xlsx, pptx, pdf, png, jpg, jpeg. Upload limit of max 3 documents.',
            docNamePlaceholderText: 'Enter Document name',
            largeFileError: 'File exceeded the maximum allowed limit.'
        },
        myAppointments: {
            myAppointmentsTitle: 'My Appointments',
            upcomingAppointmentsTitle: "Upcoming Appointments :",
            pastAppointmentsTitle: "Past Appointments :",
            printTitle: 'Print',
            fetchApptListFailure: 'Unable to fetch appointment list',
            scheduleAppointmentExistsWarning: 'Appointment already exists, click on appointment to Edit or Reschedule',
            deleteApptSuccess: 'Appointment Deleted Successfully',
            deleteApptFailure: 'Unable to Delete Appointment',
            deleteApptTitle: 'Delete Appointment',
            cancelAppointmentNotificationText: 'You have uploaded documents that will be deleted if you do not complete the scheduling process. Please return to the appointment scheduling process to complete the scheduling of these documents. Do you still wish to proceed?'
        },
        searchAppointments: {
            searchHeading: 'Search PO/SO :',
            enterCorectPOErrorMsg: 'Enter a correct PO/SO',
            enterPoTitle: 'Enter PO/SO number :',
            enterPOPlaceholder: 'Enter a PO/SO',
            searchApptsTitle: 'Search Appointments',
            noSearchPOData: 'No Appointments available for this PO/SO.',
            noSearchPODataUpdated: '* Unable to locate the PO/SO. Please try with another PO/SO',
            dateExpiredMassage: 'The due date for the PO/SO has exceeded the today date *',
            newApptBtnText: 'New Appointments',
            apptSuccessFullMsg: 'Appointment Booking successful',
            searchErrorMsg: "Both PO/SO and Zip Code are mandatory",
            poNotValidForAddon: 'The PO/SO you selected is not valid for add-on'
        },
        poSection: {
            createPOTitle: 'Create PO/SO',
            serial_num: 'Serial No.',
            editPOTitle: 'Edit PO/SO',
            downloadPOTemplate: 'Download PO/SO Template',
            uploadPOTemplate: 'Upload PO/SO',
            commodity: 'Commodity',
            produceType: 'Dock Type',
            palletsCount: 'Pallet Count',
            casesCount: 'Cases Count',
            cases:"Cases",
            buyer: 'Buyer',
            poListTitle: 'PO/SO List :',
            browseFilesTitle: 'Browse Files : ',
            uploadPoBulk: "Bulk Upload",
            warningTextPOUplaod: 'Only xlsx file of max 2MB.',
            createPoSuccess: 'Created PO successfully',
            createPoFailure: 'Unable to create PO/SO',
            updatePoSuccess: 'Updated PO/SO successfully',
            updatePoFailure: 'Unable to update PO/SO',
            uploadPoSuccess: 'Upload PO/SP successfully',
            uploadPoFailure: 'Upload to update PO/SO',
            updatePoStatusSuccess: 'Updated status successfully',
            updatePoStatusFailure: 'Unable to update Status',
            fetchPoListFailure: 'Unable to fetch PO/SO List',
            poNumTitle: 'PO Number',
            orderNumTitle: 'Foreign PO/Order Number',
            buyerNameTitle: 'Buyer Name',
            weight: 'Weight lbs',
            quantity: 'Item(s)',
            dueDate: 'Due Date',
            apptStartTime: 'Appointment Time',
            zipCodeRecvTitle: 'Zip Code (Receiving)',
            remarks: 'Remarks',
            downloadPOTemplateLink: process.env.REACT_APP_PO_TEMPLATE,
            scheduleDataWarning: "You need to click on schedule before adding more Po/So's",
            deletePOSuccess: 'Deleted Not Scheduled PO/SO succesefully',
            deletePOFailure: 'Unable to Delete Not Scheduled PO/SO',
            deletePOTitle: 'Delete Not Scheduled PO/SO',
            deletePOSuccess: 'PO Deleted succesefully',
            deletePOFailure: 'Unable to delete PO/SO',

        },
        docksTimeSlots: {
            tableHeaders: {
                timeslot: 'Timeslot',
                description: 'Description',
                displayOrderNum: 'Display Order Number',
                CreatedOn: "Created On",
                status:"Status"
            }
        },
        dockCapacity: {
            tableHeaders: {
                palletsPerHour: 'Pallets/Hr'
            }
        },
        docks: {
            createDockTitle: 'Add Dock',
            editDockTitle: 'Edit Dock',
            deleteDockTitle: 'Delete Dock',
            createDockSuccess: 'Created Dock successfully',
            createDockFailure: 'Unable to create Dock',
            updateDockSuccess: 'Updated Dock successfully',
            updateDockFailure: 'Unable to update Dock',
            deleteDockSuccess: 'Deleted Dock succesefully',
            deleteDockFailure: 'Unable to Delete Dock',
            updateDockStatusSuccess: 'Updated status successfully',
            updateDockStatusFailure: 'Unable to update Status',
            fetchDockListFailure: 'Unable to fetch Dock List',
            preCheckBockerMsg:'You cannot delete this dock as there are few dependencies. Click View Detail to manage the dock(s).',
        },
        leaves: {
            addLeaveTitle: 'Add Facility Closures',
            editLeaveTitle: 'Edit Facility Closures',
            deleteLeaveTitle: 'Delete Facility Closures',
            createLeaveSuccess: 'Created facility closures successfully',
            createLeaveFailure: 'Unable to create facility flosures',
            updateLeaveSuccess: 'Updated facility closures successfully',
            updateLeaveFailure: 'Unable to update facility closures',
            deleteLeaveSuccess: 'Deleted facility closures successfully',
            deleteLeaveFailure: 'Unable to delete facility closures',
            updateLeaveStatusSuccess: 'Updated status successfully',
            updateLeaveStatusFailure: 'Unable to update Status',
            fetchLeaveListFailure: 'Unable to fetch facility closures list',
        },
        breaksForm: {
            createBreakTitle: 'Add Break',
            editBreakTitle: 'Edit Break',
            deleteBreakTitle: 'Delete Break',
            createBreakSuccess: 'Added break successfully',
            createBreakFailure: 'Unable to create Break',
            updateBreakSuccess: 'Updated break successfully',
            updateBreakFailure: 'Unable to update break',
            deleteBreakSuccess: 'Deleted break successfully',
            deleteBreakFailure: 'Unable to delete break',
            updateBreakStatusSuccess: 'Updated status successfully',
            updateBreakStatusFailure: 'Unable to update Status',
            fetchBreakListFailure: 'Unable to fetch break List',
            tableHeaders: {
                fromTitle: 'From',
                tillTitle: 'Till',
                startTime: 'Start Time',
                endTime: 'End Time',

            }
        },
        whCapacity: {
            addMetaData: 'Add Meta Data',
            editDataTitle: 'Edit Data',
            createFetchListFailure: 'Unable to get Warehouse Capacity',
            updateCapacityStatusSuccess: 'Updated warehouse capacity successfully',
            updateCapacityStatusFailure: 'Unable to update warehouse capacity',
            createCapacityStatusFailure: 'Unable to create data',
            createCapacityStatusSuccess: 'Successfully created data for warehouse',
            formComponent: {
                whStartTime: 'Facility Start Time',
                whEndTime: 'Facility End Time',
                earliestApptHours: 'Earliest Appt Hours',
                lastApptHours: 'Last Appt Hours',
                apptAvailableColor: 'Appt Available Color',
                apptUnAvailableColor: 'Appt Unavailable Color',
                whBreakColor: 'Facility Break Color',
                whLeaveColor: 'Facility Closure Color'
            }
        },
        timeslots: {
            createTimeslotTitle: 'Add Timeslot',
            editTimeslotTitle: 'Edit Timeslot',
            deleteTimeslotTitle: 'Delete Timeslot',
            createTimeslotSuccess: 'Added timeslot successfully',
            createTimeslotFailure: 'Unable to create timeslot',
            updateTimeslotSuccess: 'Updated timeslot successfully',
            updateTimeslotFailure: 'Unable to update timeslot',
            deleteTimeslotSuccess: 'Deleted timeslot successfully',
            deleteTimeslotFailure: 'Unable to delete timeslot',
            updateTimeslotStatusSuccess: 'Updated status successfully',
            updateTimeslotStatusFailure: 'Unable to update Status',
            fetchTimeslotListFailure: 'Unable to fetch timeslot List',
        },

        subscription: {
            renewalSubscription: 'Renew Subscription',
            cancleSubscription: 'Cancel Subscription',
            daysRemainingMessage: 'Your Current subscription plan with us will expire in ',
            daysRemainingMessageNew: 'Your plan will expire in ',
            expiredPlanMessage: 'Your Current subscription plan is expired. ',
            completeSubscriptionIntro1: 'Pay subscription charges',
            welcomeBack: 'Welcome back',
            resubscribeMessage1: "Thank you for re-subscribing with",
            cancelledSubscriptionSuccessfull: 'Subscription cancelled successfully',
            cancelSubMessage1: "Your Account will be deactivated & you will no longer be able to access SchedulePro. Are you sure you want to Cancel ?",
            paymentSlip: 'Payment Slip',
            paymentIdTitle: 'Payment ID',
            paymentTitle: 'Payment',
            dateTitle: 'Date',
            popupBodyText: 'Your account will be deactivated, but you will still be able to access SchedulePro until the end of your current billing cycle.',
            popupBodyText2: 'Are you sure you want to Cancel ?',
            fetchSubsError: "Unable to fetch subscriptions."
        },
        colors: {
            green: "#018517",
            black: "#000000",

        },
        recommendations: {
            noRecommendationWarningText: 'Unable to provide a recommendation at the moment due to reaching the capacity limit.',
            recommendationDateSelectError: "Please select a date on or after today's date."
        },
        dashboardSS: {

        },
        scheduleUpload: {
            summary: "Summary",
            detailed: "Detailed"
        }

    },
    errors:{
        wentWrongReLogin:"Something went wrong, Please re-login and try again",
        selectReason:"Please select reason for cancel !",
        SMSAccess: "Atleast one user should have an SMS access"
    },
    charts:{
        appointmentSummaryReport:{
            colors:{
                warehouse:'#67bad0',
                carrier:'#4b9f94'
            }
        }
    },
    fsWarehouse:{
        errors:{
            somethingWentWrong:"Something went wrong, Please try again",
            selectReason:"Please select reason for cancel !",
            SMSAccess: "Atleast one user should have an SMS access"
        },
        eclipseOpsHeader: 'FSPay Payments',
        insertSucess: 'Warehouse Created Successfully',
        updateSucess:'Warehouse Updated Successfully',
        deleteSucess:'Warehouse Delete Successfully',
        insertFail: 'Unable to create warehouse',
        updateFail: 'Unable to update warehouse',
        deleteFail:'Unable to delete warehouse',
        deleteTimeslotTitle: 'Delete Warehouse',
        addFsWarehouseTitle: 'Add Warehouse',
        createFsWarehouse: 'Create Warehouse',
        editFsWarehouse: 'Edit Warehouse',
        whId: 'Warehouse ID (Relay Site ID)',
        whName: 'Warehouse Name',
        address:'Address',
        city:'City',
        state:'State',
        street:'Street',
        zip:'Zip',
        checkInRadius:'Check-In Radius(miles)',
        checkInRadiusEnabled:'Check-In Radius Enabled',
        checkInTime:'Check-In Time Allowance(min)',
        comdataLocationId:'Comdata Location Id',
        lat:'Latitude',
        lng:'Lognitude',
        poLength:'PO Length',
        poType:'PO Type',
        preCheckInRadius:'PreCheck-In Radius(miles)',
        preCheckInRadiusEnabled:'PreCheck-In Radius Enabled',
        preCheckInTime:'PreCheck-In Time Allowance(hr)',
        // terminalId:'Terminal Id',
        relaysiteId: 'Relay site ID',
        comdataSiteCode:'Comdata Site Code',
        prodcomdataSiteCode: 'Prod Comdata Site Code',
        tcheck_terminalId: 'T-Check Terminal ID',
        tch_terminalId: 'TCH Terminal ID',
        terminalId: 'EFS/TCHEK (Terminal ID)',
        ttwarehouse: 'TT Warehouses',
        efs_tcheck: 'EFS (Terminal ID)',
        tcheck: 'TCHEK (Terminal ID)'
        
    },
    eclipseOpsPaymentLoad:{
        shareReceiptAlert:"Receipt shared successfully",
        manualPaymentSuccess:"Payment successfully",
        paymentComplete:"Complete Payment",
        paymentMethodsFetchError:"Unable to Fetch Payment Methods."
    },
    gatePassDetails:{
        createGatePass: 'Create Gatepass',
        dockType: 'Dock Type',
        selectWarehoue: 'Select Warehouse',
        insertSucess: 'GatePass Created Successfully',
        updateSucess:'GatePass Updated Successfully',
        deleteSucess:'GatePass Delete Successfully',
        insertFail: 'Unable to create GatePass',
        updateFail: 'Unable to update GatePass',
        deleteFail:'Unable to delete GatePass',
        deleteGatePassTitle: 'Delete GatePass',
        addGatePassTitle: 'Add GatePass',
        editGatePass: 'Edit GatePass',
        doorHeaderTitle: 'Enter Door Header',
        doorInstructionsTitle: 'Enter Door Instructions',
        yardHeaderTitle: 'Enter Yard Header',
        yardInstructionsTitle: 'Enter Yard Instructions',
    }

}
