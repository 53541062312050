import Loader from "../Components/Loader/Loader";
import _ from 'lodash';
import { AppConstant } from "./AppConstant";
import moment from "moment";
import { toast } from "react-toastify";
import CryptoJS from 'crypto-js';
import Cookies from 'universal-cookie';
import successImg from '../Assests/Images/success-icon.png'
import infoImg from '../Assests/Images/info-icon.png'
import errorImg from '../Assests/Images/error-icon.png'
import warnImg from '../Assests/Images/warning-icon.png'

const calculateTimeDifference = (fromTime, toTime) => {
    // FORMAT USED -: YYYY-MM-DDTHH:MM:SS.SSSZ Example -: 2022-08-22T05:41:00.000Z

    let beginningTime = moment(fromTime);
    let endTime = moment(toTime);
    let difftmin = endTime.diff(beginningTime, 'minutes');
    let diffthrs = endTime.diff(beginningTime, 'hours');

    const minLeft = difftmin - diffthrs * 60;

    const minLeft1 = String(minLeft).replaceAll('-', '')

    const minLeft2 = minLeft1.length < 2 ? '0' + minLeft1 : minLeft1;

    return (`${difftmin < 0 ? '-' : ''}${String(diffthrs).replaceAll('-', '')}:${String(minLeft2)}`);

}

const dateAndTimeUTCFormat = (date) => {
    return {
        date: moment(date).utc().format('MM/DD/YYYY'),
        time: moment(date).utc().format('HH: mm'),
    }
}

const dateAndTimeLocal = (date) => {
    return {
        date: moment(date).format('MM/DD/YYYY'),
        time: moment(date).format('hh:mm'),
    }
}

const changeFormatWithoutTz = (dateStr, format) => moment(dateStr).utcOffset(0, false).format(format);

const dateTimeFormatter = (dateObj) => {
    let amPm = 'AM'
    if (dateObj.getHours() >= 12) {
        amPm = 'PM'
    }
    return {
        date: ("0" + (dateObj.getMonth() + 1)).slice(-2) + "/" + ("0" + dateObj.getDate()).slice(-2) + "/" + dateObj.getFullYear().toString().substr(-2),
        time: ("0" + dateObj.getHours()).slice(-2) + ":" + ("0" + dateObj.getMinutes()).slice(-2) + " " + amPm
    }
}

const isCheckedInMoreThan15Min = (truck) => {
    if (truck.appointment_time && truck.check_in_time && !truck.indoor_time && !truck.door) {
        if (truck.check_in_time > truck.appointment_time) {
            //if truck is not early
            const checkInApptTimeDifference = calculateTimeDifference(truck.appointment_time, truck.check_in_time)
            const timeDiffInMinutes = (moment(checkInApptTimeDifference, 'HH:mm')).minute()
            if (timeDiffInMinutes < 60) {
                //if truck is not in detention (is not late more than 60 min)
                // const currentDate = moment().toDate().format("yyyy-MM-dd'T'HH:mm:ss.SSS'Z'");
                const currentDate = new Date().toISOString();
                const isWaitingFor15Minutes = (calculateTimeDifference(truck.check_in_time, currentDate));
                const waitTimeinMinutes = moment.duration(isWaitingFor15Minutes).asMinutes()
                if (waitTimeinMinutes > 15) {
                    //truck waiting for more than 15 minutes
                    return 'waitingFor15MinOrMore'
                }
            }
        } else {
            //if checkin in is earlier than appointment time
            const currentDate = new Date().toISOString();
            const isWaitingFor15Minutes = (calculateTimeDifference(truck.appointment_time, currentDate));
            const waitTimeinMinutes = moment.duration(isWaitingFor15Minutes).asMinutes()
            if (waitTimeinMinutes > 15 && currentDate > truck.appointment_time) {
                //truck waiting for more than 15 minutes
                return 'waitingFor15MinOrMore'
            }
        }
    }
    return ''
}

const reshuffelTruckList = (lateTrucks, truckList) => {
    const remainingTrucks = _.difference(truckList, lateTrucks)
    return [...lateTrucks, ...remainingTrucks];
}

const modifyTruckData = (truckList, arrange) => {
    const orderedTruckList = _.orderBy(truckList.map((el, i) => {
        let newEl = { ...el }
        newEl.po = [...newEl.scheduled_pos, ...newEl.unscheduled_pos];
        newEl.checked = false;
        return newEl;
    }), [arrange.key], [arrange.order])

    const lateTrucks = orderedTruckList.filter((truck, i) => {
        if (isCheckedInMoreThan15Min(truck) !== '') {
            return truck
        }
    })

    return reshuffelTruckList(lateTrucks, orderedTruckList);

}

const modifyPODisplay = (pos) => {
    if (!pos.length) return '-';
    let morePoList = '';
    if (pos.length > 1) {
        let customizePoList = pos[0];
        morePoList = `${customizePoList} +${pos.length - 1} `;
    }
    else if (pos.length <= 3 && pos.length > 0) {
        pos.forEach((poEl, i) => {
            if (i !== pos.length - 1) {
                morePoList += poEl
            } else {
                morePoList = morePoList + poEl
            }
        })
    }
    return morePoList
}

const modifyDoorList = (allDoorlist) => {
    return [...allDoorlist].map((el) => {
        let classNameDoor = ''

        switch (el.door_status) {
            case '':
                classNameDoor = 'operable';
                break;
            case 'Available':
                classNameDoor = 'operable';
                break;
            case 'Assigned':
                classNameDoor = 'operable';
                break;
            case 'Assign':
                classNameDoor = 'operable';
                break;
            case 'Out of Order':
                classNameDoor = 'inoperable';
                break;
            case 'Inoperable':
                classNameDoor = 'inoperable';
                break;
            case 'Cleaning':
                classNameDoor = 'inoperable';
                break;
            case 'Occupied':
                classNameDoor = 'inUse';
                break;
            case 'In Use':
                classNameDoor = 'inUse';
                break;
            case 'Available - IN USE':
                classNameDoor = 'inUse';
                break;
            // case ' - IN USE ':
            //     classNameDoor = 'inUse';
            // break;
            case 'Available - IN USE ':
                classNameDoor = 'inUse';
                break;
            case 'Blocked':
                classNameDoor = 'blocked';
                break;
            case 'Blocked - Floor ':
                classNameDoor = 'blocked';
                break;
            case 'Blocked - Floor':
                classNameDoor = 'blocked';
                break;
            case 'Blocked - Shipping Trailer':
                classNameDoor = 'blocked';
                break;
            case 'Blocked - Backhaul/Dropped':
                classNameDoor = 'blocked';
                break;
            case 'Blocked - Backhaul/Dropped - IN USE':
                classNameDoor = 'blocked';
                break;
            case 'Not Available':
                classNameDoor = 'inoperable';
                break;
            case 'Not Available':
                classNameDoor = 'blocked';
                break;
            default:
                classNameDoor = 'available';
                break;
        }

        return { ...el, className: classNameDoor, selected: false }
    })
}

const displayLoader = (themeSelected, height, count) => {
    return (
        <Loader height={height || 35} count={count || 1} highlightColor={themeSelected === 'dark-mode' ? '#151515' : ''}
            baseColor={themeSelected === 'dark-mode' ? '#575757'
                : ''}
        />
    )
}

const mapDoorStatusToID = (statusId) => {
    if (statusId) return AppConstant.doorManagement.doorOption.doorStatusId[statusId]
}

function showAlert(type, message, themeSelected, toastposition) {
    const getIcon = () => {
        switch (type) {
            case "success":
                return successImg;
            case "error":
                return errorImg;
            case "warning":
                return warnImg;
            default:
                return infoImg;
        }
    }

    const getType = () => {
        return <div className="d-flex align-items-center"><img src={getIcon()} alt=" " /><p className="ms-3 f-12 fw-500">{message}</p></div>
    }

    let toastOptions = {
        position: toastposition ? toastposition : toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        newestOnTop: true,
        type: type,
        autoClose: 3000,
        theme: themeSelected === 'dark-mode' ? 'dark' : 'light'
    };

    // const autoClose = type === toast.TYPE.ERROR;
    // toastOptions.autoClose = autoClose;
    toastOptions.type = type;

    const toastId = toast(getType(), toastOptions);

    return toastId;

}

const encryptString = (nameOfProject, message, secret) => {

    const cryptUsernameObj = {
        name: nameOfProject,
        message: message,
        secret: secret,
        cipher: "",
        decrypted: ""
    }

    const cipherUsernameText = CryptoJS.AES.encrypt(cryptUsernameObj.message, cryptUsernameObj.secret);
    cryptUsernameObj.cipher = cipherUsernameText;

    return cipherUsernameText.toString();

}

const decryptString = (key) => {

    let bytes
    try {
        bytes = CryptoJS.AES.decrypt(getCookies(key), key);
        const decrypted = bytes.toString(CryptoJS.enc.Utf8);
        return decrypted;
    }
    catch (err) {
        return '';
    }
}

const setCookies = (key, data, path) => {
    const cookies = new Cookies();
    cookies.set(key, data, { path: path });
}

const getCookies = (key) => {
    const cookies = new Cookies();
    return cookies.get(key)
}

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}

const formatFirstCharBold = (str) => {
    const strArr = str.split(' ');
    const strFormatted = strArr.map((ele) => {
        return <span>
            <span className='whtitle'>{Array.from(ele)[0]}</span>
            <span>{ele.slice(1)} </span>
        </span>
    })
    return strFormatted
}
/**
 * Reusable function create dynamic Navigation bar
 * @param {*} permissions 
 * @param {*} userType 
 * @param {*} roleName 
 * @param {*} userWarehouseList 
 * @returns 
 */
const createNavBar = (permissions, userType, roleName, userWarehouseList) => {
    let userPermissions = [];
    let tempPermissions = [];
    if (userType === AppConstant.userPermission.usertypes.user && roleName.toUpperCase
        () !== AppConstant.userPermission.adminRoles.ccAdmin) {
        if (!(_.upperCase(roleName)).includes('GUARD') && _.size(userWarehouseList) > 1) {
            userPermissions.push({
                PageDesc: 'Home',
                PageUrl: '/',
                PageAccess: true,
                NavigationType: 'Primary'
            })
        }
        tempPermissions = permissions.map(el => el.PageAccess ? el : null)

    }
    else if (userType === AppConstant.userPermission.usertypes.superAdmin || roleName?.toUpperCase
        () === AppConstant.userPermission.adminRoles.ccAdmin) {
        tempPermissions = permissions.map(el => el.PageAccess ? el : null)
    }
    else if (userType === AppConstant.userPermission.usertypes.carrier) {
        tempPermissions = permissions.map(el => el.PageAccess ? el : null)
    }
    else if (userType === AppConstant.userPermission.superwarehouse) {
        userPermissions.push({
            PageDesc: 'Home',
            PageUrl: '/warehousemanagement',
            PageAccess: true,
            NavigationType: 'Primary'
        })
        tempPermissions = permissions.map(el => el.FeatureID === '6' || el.FeatureID === '7' ? el : null)
    }
    tempPermissions = _.compact(tempPermissions)
    return [...userPermissions, ...tempPermissions]

}

const isCheckPermission = (element) => {
    return element === true
}

const shouldHavePermission = (permissions, ElementID) => {
    let hasPermission = []
    permissions.forEach(el => {
        if (el?.featureRole.length) {
            el.featureRole.forEach(ele => {
                if (ele['ElementAccessID'] === ElementID && ele.Permission) {
                    hasPermission.push(ele)
                }
            })

        }
    })
    return hasPermission.length ? true : false;
}
const validateCreateUserForm = (list, inputData) => {
    Object.entries(inputData).forEach(([key, value]) => {
        console.log(`${key} ${value}`); // "a 5", "b 7", "c 9"
    });

}

const createUserPayload = (response, data, isSPuser) => {
    let username = data.user_name.split('@')
    return {
        user_name: username[0],
        email: data.user_name,
        enable_status: 1,
        cu_id: response.User.Username,
        user_type: AppConstant.userPermission.usertypes.user,
        crud_type: 'I',
        first_name: data.given_name,
        last_name: data.family_name,
        phone: data.phone_number.replaceAll('+1', ''),
        p_cu_id: data.p_cu_id,
        warehouseId: data.warehouseId,
        user_add_type: isSPuser,
        EmailAccess: isSPuser ? isSPuser : false,
        SMSAccess: isSPuser ? isSPuser : false
    }

}

const createCarrierSubUserPayload = (response, data, type) => {
    let username = data.user_name.split('@')
    return {
        UserName: username[0],
        Email: data.user_name,
        CreatedBy: data.p_cu_id,
        CreatedOn: new Date(),
        EnableStatus: 1,
        CUID: response.User.Username,
        CRUDTYPE: type,
        LastName: data.family_name,
        FirstName: data.given_name,
        phone: data.phone_number.replaceAll('+1', ''),
        IDCarrier: data.IDCarrier,
        CarrierName: "",
        PrimaryUserCUID: data.p_cu_id
    }

}

const updateUserPayload = (user, isPayload, cuid, warehouseId) => {
    if (isPayload) {
        return {
            user_name: user.user_name,
            email: user.email,
            enable_status: user.enable_status,
            cu_id: user.cu_id,
            user_type: user.user_type,
            crud_type: 'U',
            first_name: user.given_name,
            last_name: user.family_name,
            phone: user.phone_number,
            p_cu_id: cuid,
            warehouseId: warehouseId,
            EmailAccess: user.EmailAccess
        }
    } else {
        return {
            user_name: user.UserName,
            email: user.Email,
            family_name: user.LastName,
            given_name: user.FirstName,
            phone_number: user.Phone,
            enable_status: user.EnableStatus,
            cu_id: user.CUID,
            user_type: user.UserType,
            crud_type: 'U',
            EmailAccess: user.EmailAccess
        }
    }

}


const updateCarrierSubUserPayload = (user, status = 1) => {
    let username = user.email.split('@')[0]
    return {
        UserName: username,
        Email: user.email,
        CreatedBy: user.p_cu_id,
        CreatedOn: user.createdOn,
        UpdatedBy: user.createdby,
        EnableStatus: status,
        CUID: user.cu_id,
        CRUDTYPE: 'U',
        LastName: user.family_name,
        FirstName: user.given_name,
        phone: user.phone_number.replaceAll('+1', ''),
        IDCarrier: user.IDCarrier,
        CarrierName: "",
        PrimaryUserCUID: user.p_cu_id,
    }
}

const updateUserWarehouseListRespone = (res) => {
    return res.map(el => {
        el.isloading = false
        return el
    })

}

const addSerialNumberToList = (data) => {
    return data.map((el, i) => {
        let newEl = { ...el };
        newEl.serial_num = i + 1
        return newEl
    })
}
/**
 * Reusable function to filter array based on searchStr
 * @param {*} arr 
 * @param {*} searchStr 
 * @param {*} searchParam 
 * @returns filtered array
 */
function initiateSearch(arr, searchStr, searchParam) {
    if (arr && arr.length && searchStr && searchStr.length) {

        const searchedArr = arr.filter((arrObj) => {
            const valuesArr = Object.values(arrObj);
            const arrTemp = valuesArr.filter((objValue) => {

                if (objValue && _.includes(objValue.toString().toLowerCase().trim(), searchStr.toLowerCase().trim())) {
                    return arrObj
                }
            });
            if (arrTemp && arrTemp.length) {
                return arrTemp
            }

        });


        return (searchedArr);
    }
}

const sortArrByKey = (arr, key) => {
    return (_.sortBy(arr, key))
}

const sortAndFilterArrByKey = (arr, key) => {
    //   _.filter(_.sortBy(arr, key), function(item) {
    //     return item.WHTable === 'SPRO';
    //   });

    const sortedArr = _.sortBy(arr, key);
    return _.filter(sortedArr, { WHTable: 'SPRO' });
}

const sortArrAplhabeticalyByKey = (arr, key) => {
    return  _.sortBy(arr, key);
}

const numberWithCommas = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function getFormattedContactNum(input) {
    input = input.replace(/\D/g, '');
    var size = input.length;
    if (size > 0) { input = "(" + input }
    if (size > 3) { input = input.slice(0, 4) + ") " + input.slice(4) }
    if (size > 6) { input = input.slice(0, 9) + "-" + input.slice(9) }
    return input;
}

const formatContactNumNNN = (contactNum) => {
    const x = contactNum && contactNum.replaceAll('+1', '').replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    return !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ''}`;
}

const reverseArray = (array) => array.map((item, idx) => array[array.length - 1 - idx]);

const createWPMetaData = (key, crudType, data) => {
    if (key === 'wpCapacityData') {
        return {
            PPH: data.PPH || null,
            WHStartTime: data.WHStartTime || null,
            WHEndTime: data.WHEndTime || null,
            EarliestApptHrs: data.EarliestApptHrs || null,
            LastApptHrs: data.LastApptHrs || null,
            ApptAvailableColor: data.ApptAvailableColor || null,
            ApptNotAvailableColor: data.ApptNotAvailableColor || null,
            WHBreakColor: data.WHBreakColor || null,
            WHLeaveColor: data.WHLeaveColor || null,
            WHType: data.WHType || null,
            ID: data?.IDWarehouse,
            CRUDTYPE: crudType
        }
    } else if (key === 'wpBreakData') {
        return {
            IDWHBreak: data.IDWHBreak || null,
            IDWarehouse: data.IDWarehouse || null,
            BreakStart: data.BreakStart || null,
            BreakEnd: data.BreakEnd || null,
            Description: data.Description || null,
            Status: data.Status || null,
            CreatedBy: data.CreatedBy || null,
            UpdatedBy: data.UpdatedBy || null,
            IDWarehouse: data?.IDWarehouse,
            CRUDTYPE: crudType
        }

    } else if (key === 'wpLeaveData') {
        return {
            IDWHLeave: data.IDWHLeave || null,
            IDWarehouse: data?.IDWarehouse,
            Purpose: data.Purpose || null,
            LeaveStart: data.LeaveStart || null,
            LeaveEnd: data.LeaveEnd || null,
            Status: data.Status || null,
            CreatedBy: data.CreatedBy || null,
            UpdatedBy: data.UpdatedBy || null,
            CRUDTYPE: crudType
        }
    }

}

const removeLastCharacterFromString = (str) => str.slice(0, -1);

const formatContactNumForAWS = (str) => '+1' + str.replaceAll('(', '').replaceAll(')', '').replaceAll(' ', '').replaceAll('-', '');

const calculateDaysDifference = (date1, date2) => parseInt((new Date(date1) - new Date(date2)) / (1000 * 60 * 60 * 24), 10);

const customStylesLight = () => {
    return {
        control: (base, state) => ({
            ...base,
            background: "#fff",
            border: '1px solid #e6e6e6',
            color: '#000',
            minHeight: '42px',

            // match with the menu
            //   borderRadius: state.isFocused ? "3px 3px 0 0" : 3,
            // Overwrittes the different states of border
            // Removes weird border around container
            "&:hover": {
                // Overwrittes the different states of border
                borderColor: '#404954',
                color: '#000'
            }
        }),
        menu: base => ({
            ...base,
            // override border radius to match the box
            borderRadius: 0,
            background: "#fff",
            color: '#000',
            // kill the gap
            marginTop: 0
        }),
        menuList: base => ({
            ...base,
            // kill the white space on first and last option
            background: "#fff",
            color: '#000',
            padding: 0,

        }),
        option: (base, state) => ({
            ...base,
            // kill the white space on first and last option
            background: "#fff",
            color: '#000',
            "&:hover": {
                // Overwrittes the different states of border
                background: "#68696b6b"
            }
        }),
        singleValue: base => ({
            ...base,
            color: '#000'
        }),
        input: base => ({
            ...base,
            color: '#000'
        })
    }
}


const areObjectsEqual = (obj1, obj2) => {
    return _.isEqual(obj1, obj2);
};

// resusbale function to show loader
const showLoader = (text) => {
    return (
        <>
            <div id="loading-wrapper">
                <div id="loading-text">LOADING</div>
                <div id="loading-content"></div>
                <div className='content text-align-center'><h3>{text}</h3></div>
            </div>

        </>
    )
}

const formParticularFieldValue = (rowData) => {
    let data = {
        po_number: [],
        ReceivingZipCode: "",
    };
    rowData.forEach((el, i) => {
        data.po_number.push(el.PoNumber);
        if (i === 0) {
            data.ReceivingZipCode = el.ReceivingZipCode;
            data.WarehouseName = el.WarehouseName;
        }
    });
    return {
        po_number: data.po_number.toString(),
        ReceivingZipCode: data.ReceivingZipCode,
        WarehouseName: data.WarehouseName,
    };
};

const formParticularFieldValueForAssociatedPO = (rowData) => {
    let data = {
        po_number: [],
        ReceivingZipCode: "",
    };
    rowData.forEach((el, i) => {
        i !== 0 && data.po_number.push(el.PoNumber);
        if (i === 0) {
            data.ReceivingZipCode = el.ReceivingZipCode;
            data.WarehouseName = el.WarehouseName;
        }
    });
    return {
        po_number: data.po_number.toString(),
        ReceivingZipCode: data.ReceivingZipCode,
        WarehouseName: data.WarehouseName,
    };
};

const formatPOwithPORelationID = (data) => {
    let result = []
    const tempArr = _.size(data) && data.map(ele => {
        if (ele.PORelationID === ele.PoNumber) {
            return ({ ...ele, masterPO: true })
        }
        else {
            return ({ ...ele, masterPO: false })
        }
    })
    let newlist = _.groupBy(tempArr, 'PORelationID')
    let finaldata = Object.values(newlist);
    finaldata.forEach(element => {
        let l = element.sort((a, b) => {
            if (a.masterPO) {
                return -1;
            } else {
                return 1;
            }
        })
        result.push(l)
    });
    return result;
}

const formatDate_MM_DD_YYYY_HH_MM_SS = (selectedDate) => {
    const formattedDate = `${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}-${selectedDate.getFullYear()} ${selectedDate.getHours().toString().padStart(2, '0')}:${selectedDate.getMinutes().toString().padStart(2, '0')}:${selectedDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate
}

const formatDate_MM_DD_YYYYTHH_MM_SS = (selectedDate) => {
    const formattedDate = `${selectedDate.getFullYear()}-${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}T${selectedDate.getHours().toString().padStart(2, '0')}:${selectedDate.getMinutes().toString().padStart(2, '0')}:${selectedDate.getSeconds().toString().padStart(2, '0')}`;
    return formattedDate
}

const formatDate_MM_DD_YYYY = (selectedDate) => {
    const formattedDate = `${(selectedDate.getMonth() + 1).toString().padStart(2, '0')}-${selectedDate.getDate().toString().padStart(2, '0')}-${selectedDate.getFullYear()}`;
    return formattedDate
}

/**
 * Method is used to check for dev env
 * @returns 
 */
const isDevEnv = () => process.env.REACT_APP_ENV === 'development'

const formatConfigData = (data) => {
    let newObj = {
    }
    data.forEach((el) => {
        newObj[el.ConfigKey] = el.ConfigValue
    })
    return newObj
}
// Reusable function to create dropdown data for Reports - Guards
const formDropDownDataforGaurd = (data, type) => {
    return data[type].slice(1, -1).split(',').map((ele, index) => {
        return (
            <option key={index} value={ele}>
                {ele}
            </option>
        );
    })
}

// Function to get validate active users in warehouse
const getActiveUserCount = (userList) => {
    if (_.size(userList)) {
        return _.size(_.groupBy(userList, "EnableStatus").true)
    }
    else {
        return 0
    }
}

// Function to get validate number of users having Email Access
const getActiveEmailAccessUserCount = (userList) => {
    if (_.size(userList)) {
        return _.size(_.groupBy(userList, "EmailAccess").true)
    }
    else {
        return 0
    }
}

/**
 * Fucntion used to create time slots for Manual Override
 * @param {*} startDate 
 * @param {*} timeDifference 
 * @param {*} numberOfEntries 
 * @returns Array with time from 12:00am to 11:00pm 
 */
const generateManualOverrideTimeSlot = (startDate, timeDifference, numberOfEntries, startFrom = 0) => {
    const data = [];

    for (let i = startFrom; i < numberOfEntries; i++) {
        const newDate = new Date(startDate);
        newDate.setHours(newDate.getHours() + i * timeDifference);

        data.push({ ApptStartTime: newDate.toISOString() });
    }
    data.sort((a, b) => a.ApptStartTime.localeCompare(b.ApptStartTime));
    return data;
}

/**
 * Function to check reschedule case
 * @param {*} data 
 * @returns 
 */
const isRescheduleCase = (data, isCarrierUser) => {
    if (isCarrierUser) {
        return (data?.ApptScheStatus?.toLowerCase() === 'scheduled' || data?.ApptScheStatus?.toLowerCase() === 'rescheduled' ? true : false)

    } else {
        return (data?.ApptScheStatus?.toLowerCase() === 'scheduled' || data?.ApptScheStatus?.toLowerCase() === 'rescheduled' || data?.ApptScheStatus?.toLowerCase() === 'completed' ? true : false)
    }
}

// Reusable function to create dropdown data from configration data
const formDropDownData = (data, type) => {
    return data[type].slice(1, -1).split(',').sort().map((ele, index) => {
        return (
            <option key={index} value={ele}>
                {ele}
            </option>
        );
    })
}

// Function to get validate number of users having SMS Access
const getActiveSMSAccessUserCount = (userList) => {
    if (_.size(userList)) {
        return _.size(_.groupBy(userList, "SMSAccess").true)
    }
    else {
        return 0
    }
}
/**
 * Reusable funtion to form payload from multi select dropdown
 * @param {*} list 
 * @returns 
 */
const formMultiSelectDataForPayload = (list) => {
    let filters = ""
    list.forEach((el) => {
        filters = filters + el.toLowerCase() + ",";
    })
    return filters;
}
/**
 * Calculates the adjusted page number based on the total number of items and the current page number.
 * If the total number of items changes and the current page becomes invalid, it adjusts the page number.
 * @param {number} pageNo - The current page number.
 * @param {number} limitPerPage - The number of items per page.
 * @param {number} totalItems - The total number of items.
 * @returns {number} - The adjusted page number based on the total number of items.
 */
const calculatePageNumber = (pageNo, limitPerPage, totalItems) => {
    const totalPages = Math.ceil(totalItems / limitPerPage);

    if (totalItems === 0) {
        return 1;
    } else if (pageNo > totalPages) {
        return totalPages;
    } else {
        return pageNo;
    }
}

/**
 * Function is used to convert miliseconds to hours or minutes based on the type parameter
 * @param {*} milliseconds 
 * @param {*} type 
 * @returns 
 */
const convertMiliSecondsToHrOrMin = (milliseconds, type) => {
    if(type==='HOUR'){
        return Math.floor(milliseconds / 3600000);
    }else {
        return Math.floor(milliseconds / 60000);
    }
}

/**
 * Function is used to convert hours or minutes to miliseconds based on the type parameter
 * @param {*} time 
 * @param {*} type 
 * @returns 
 */
const convertHrOrMinToMiliSeconds = (time, type) => {
    if(type==='HOUR'){
        return time * 3600000;
    }else {
        return time * 60000;
    }
}


export {
    modifyTruckData, dateTimeFormatter, displayLoader, calculateTimeDifference,
    mapDoorStatusToID, modifyDoorList, dateAndTimeUTCFormat,
    showAlert, encryptString, decryptString, setCookies, getCookies,
    isCheckedInMoreThan15Min, modifyPODisplay, uuidv4,
    createNavBar, isCheckPermission, shouldHavePermission,
    validateCreateUserForm, createUserPayload, formatFirstCharBold,
    updateUserPayload, updateUserWarehouseListRespone, addSerialNumberToList,
    dateAndTimeLocal, initiateSearch, sortArrByKey, sortAndFilterArrByKey,
    numberWithCommas, getFormattedContactNum, formatContactNumNNN,
    reverseArray, removeLastCharacterFromString, formatContactNumForAWS,
    createWPMetaData, customStylesLight, calculateDaysDifference,
    changeFormatWithoutTz, areObjectsEqual, showLoader,
    formParticularFieldValue, formParticularFieldValueForAssociatedPO,
    formatPOwithPORelationID, formatDate_MM_DD_YYYY_HH_MM_SS,
    formatDate_MM_DD_YYYY, formatDate_MM_DD_YYYYTHH_MM_SS,
    createCarrierSubUserPayload, updateCarrierSubUserPayload, isDevEnv, formatConfigData, formDropDownDataforGaurd,
    getActiveUserCount, generateManualOverrideTimeSlot, isRescheduleCase, formDropDownData, formMultiSelectDataForPayload, getActiveEmailAccessUserCount, getActiveSMSAccessUserCount, calculatePageNumber, sortArrAplhabeticalyByKey, convertMiliSecondsToHrOrMin, convertHrOrMinToMiliSeconds 
}
